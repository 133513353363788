import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import '@mantine/core/styles.css';
import App from "App";

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";
import { MantineProvider } from "@mantine/core";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <SoftUIControllerProvider>
    <MantineProvider defaultColorScheme='light'>

      <App />
      
      </MantineProvider>
    </SoftUIControllerProvider>
  </HashRouter>
);
