import React, { useState } from "react";
import { Card, Grid, FormControl, FormHelperText, Tooltip } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import { APIData, org } from 'authentication/APIData';

function AddNewFestival() {
    const [festivalName, setFestivalName] = useState("");
    const [festivalDate, setFestivalDate] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "File too large. Max file size is 1MB.",
                });
                setImage(null);
                setImagePreview("");
                return;
            }
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
            setErrors((prevErrors) => ({ ...prevErrors, image: "" }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
    
        const newErrors = {};
    
        // Validate required fields
        if (!festivalName) newErrors.festivalName = "Festival Name is required.";
        if (!festivalDate) newErrors.festivalDate = "Festival Date is required.";
        if (!description) newErrors.description = "Description is required.";
        if (!image) newErrors.image = "Image is required.";
    
        // Check if the festival name contains numbers
        if (/\d/.test(festivalName)) {
            newErrors.festivalName = "Festival Name cannot contain numbers.";
        }
    
        // Check if the selected festival date is in the past
        const today = new Date();
        const selectedDate = new Date(festivalDate);
        if (festivalDate && selectedDate < today) {
            newErrors.festivalDate = "Festival Date cannot be in the past.";
        }
    
        // Check if description exceeds 250 characters
        if (description.length > 250) {
            newErrors.description = "Description should be below 250 characters.";
        }
    
        setErrors(newErrors);
    
        // If there are errors, stop the submission
        if (Object.keys(newErrors).length > 0) {
            setLoading(false);
            return;
        }
    
        const festivaldata = new FormData();
        festivaldata.append("image", image);
        festivaldata.append("fest_name", festivalName);
        festivaldata.append("fest_date", festivalDate);
        festivaldata.append("des", description);
        festivaldata.append("org", org);
    
        festivaldata.forEach((value, key) => {
            console.log(`${key}: ${value}`);
        });
    
        try {
            const url = `${APIData.api}festivals/`;
            const response = await axios.post(url, festivaldata, { headers: APIData.headers });
    
            Swal.fire({
                icon: "success",
                title: "Festival Added Successfully",
                showConfirmButton: false,
                timer: 1500,
            });
    
            setLoading(false);
            setTimeout(() => {
                navigate("/festival-list");
            }, 1500);
        } catch (error) {
            console.error(error);
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to add festival",
                footer: error.message,
            });
        }
    };
        

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
                <Grid item xs={10} sm={10} md={8} lg={6}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Tooltip title="Close" arrow>
                                <Link to="/festival-list">
                                    <AiIcons.AiOutlineCloseCircle />
                                </Link>
                            </Tooltip>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Add New Festival
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h6">Festival Name *</SoftTypography>
                                <FormControl fullWidth error={!!errors.festivalName}>
                                    <SoftInput
                                        placeholder="Festival Name"
                                        value={festivalName}
                                        onChange={(e) => setFestivalName(e.target.value)}
                                    />
                                    {errors.festivalName && (
                                        <FormHelperText>{errors.festivalName}</FormHelperText>
                                    )}
                                </FormControl>
                            </SoftBox>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h6">Festival Date *</SoftTypography>
                                <FormControl fullWidth error={!!errors.festivalDate}>
                                    <SoftInput
                                        type="date"
                                        value={festivalDate}
                                        onChange={(e) => setFestivalDate(e.target.value)}
                                    />
                                    {errors.festivalDate && (
                                        <FormHelperText>{errors.festivalDate}</FormHelperText>
                                    )}
                                </FormControl>
                            </SoftBox>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h6">Description *</SoftTypography>
                                <FormControl fullWidth error={!!errors.description}>
                                    <SoftInput
                                        placeholder="Description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    {errors.description && (
                                        <FormHelperText>{errors.description}</FormHelperText>
                                    )}
                                </FormControl>
                            </SoftBox>
                            <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="upload-image"
                                    type="file"
                                    onChange={handleImageChange}
                                />
                                <label htmlFor="upload-image">
                                    <SoftButton variant="contained" component="span">
                                        Upload Image
                                    </SoftButton>
                                </label>
                                {imagePreview && (
                                    <SoftBox mt={2} display="flex" justifyContent="center" alignItems="center">
                                        <img src={imagePreview} alt="Preview" style={{ width: '200px', height: 'auto', objectFit: 'contain' }} />
                                    </SoftBox>
                                )}
                                {errors.image && (
                                    <FormHelperText error>{errors.image}</FormHelperText>
                                )}
                            </SoftBox>
                            <SoftBox mt={4} mb={1}>
                                <SoftButton type="submit" variant="gradient" color="info" fullWidth disabled={loading}>
                                    {loading ? "Submitting..." : "Submit"}
                                </SoftButton>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default AddNewFestival;