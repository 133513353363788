import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Backdrop } from '@mui/material';
import { Card } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { toast } from 'react-toastify';
import * as AiIcons from "react-icons/ai";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { APIData } from 'authentication/APIData';
import Swal from 'sweetalert2';



export const CreateSuperadmin = () => {
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const user = sessiondetails.userName
    const [orgOptions, setOrgOptions] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState({
        email_id: '',
        org: '',
        password: '',
        phone_number: '',
        role: 'SUPERADMIN',
        status: 'active',
        updated_by: user,
        username: '',
        otp: 'string',
        otpExpirationTime: ''
    });

    const [params, setParams] = useState({
        name: '',
        branch: ''
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchOrgOptions = async () => {
            try {
                const response = await axios.get(`${APIData.api}org-mdm/`);
                const orgData = response.data;
                const formattedOptions = orgData.map(org => ({
                    value: org.orgId,
                    label: org.orgId,
                    orgSpocName: org.orgSpocName,
                    orgShortAddress: org.orgShortAddress,
                    orgSpocEmailId: org.orgSpocEmailId,
                    orgSpocMob: org.orgSpocMob
                }));
                setOrgOptions(formattedOptions);
            } catch (error) {
                console.error('Error fetching organization data:', error);
                toast.error("Failed to fetch organization data");
            }
        };

        fetchOrgOptions();
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        if (actionMeta.name === 'org' && selectedOption) {
            const selectedOrg = orgOptions.find(org => org.value === selectedOption.value);
            if (selectedOrg) {
                setData(prevData => ({
                    ...prevData,
                    org: selectedOrg.value,
                    email_id: selectedOrg.orgSpocEmailId || '',
                    phone_number: selectedOrg.orgSpocMob || '',
                    status: 'active'
                }));
                setParams({
                    name: selectedOrg.orgSpocName || '',
                    branch: selectedOrg.orgShortAddress || ''
                });
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));

        const error = validateField(name, value);
        setErrors(prev => ({
            ...prev,
            [name]: error
        }));
    };

    const validateField = (name, value) => {
        const validations = {
            email_id: (val) => {
                if (!val.trim()) return "Email is required";
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val)) return "Invalid email format";
                return '';
            },
            phone_number: (val) => {
                if (!val.trim()) return "Phone number is required";
                if (!/^\d{10}$/.test(val)) return "Phone number must be 10 digits";
                if (parseInt(val[0]) < 6) return "Phone number must start with 6 or greater";
                return '';
            },
            username: (val) => !val.trim() ? "Username is required" : '',
            password: (val) => !val.trim() ? "Password is required" : '',
            org: (val) => !val ? "Organization is required" : ''
        };

        return validations[name] ? validations[name](value) : '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const newErrors = {};
        const requiredFields = ['email_id', 'phone_number', 'username', 'password', 'org'];

        requiredFields.forEach(field => {
            const error = validateField(field, data[field]);
            if (error) {
                newErrors[field] = error;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            toast.error("Please fill all required fields correctly");
            setLoading(false);
            return;
        }
console.log(data);

        try {

            const url = `${APIData.api}login-type/create-superadmin?name=${params.name}&branch=${params.branch}`;
            console.log(url);
            const response = await axios.post(url, data, { headers: APIData.headers });

            if (response.status === 200 || response.status === 201) {
                Swal.fire({
                    icon: "success",
                    title: "Created SuperAdmin",
                    showConfirmButton: false,
                    timer: 1500
                });

            }
        } catch (error) {
            console.error('Error creating superadmin:', error.response?.data || error.message);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response?.data?.message || "Cannot create superadmin",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs={12} sm={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Create Superadmin
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={1} pb={3} px={3}>
                            <SoftBox component="form" onSubmit={handleSubmit}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Organization *</SoftTypography>
                                    <CustomSelect
                                        options={orgOptions}
                                        placeholder="Select Organization"
                                        name="org"
                                        onChange={handleSelectChange}
                                        value={orgOptions.find(option => option.value === data.org)}
                                        error={!!errors.org}
                                    />
                                    {errors.org && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.org}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Name</SoftTypography>
                                    <SoftInput
                                        placeholder="Name"
                                        name="name"
                                        value={params.name}
                                        onChange={e => setParams(prev => ({ ...prev, name: e.target.value }))}
                                        disabled
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Branch</SoftTypography>
                                    <SoftInput
                                        placeholder="Branch"
                                        name="branch"
                                        value={params.branch}
                                        onChange={e => setParams(prev => ({ ...prev, branch: e.target.value }))}
                                        disabled
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Email *</SoftTypography>
                                    <SoftInput
                                        type="email"
                                        placeholder="Email"
                                        name="email_id"
                                        value={data.email_id}
                                        onChange={handleChange}
                                        error={!!errors.email_id}
                                    />
                                    {errors.email_id && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.email_id}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Phone Number *</SoftTypography>
                                    <SoftInput
                                        placeholder="Phone Number"
                                        name="phone_number"
                                        value={data.phone_number}
                                        onChange={handleChange}
                                        error={!!errors.phone_number}
                                        inputProps={{
                                            maxLength: 10,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                        }}
                                    />
                                    {errors.phone_number && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.phone_number}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Username *</SoftTypography>
                                    <SoftInput
                                        placeholder="Username"
                                        name="username"
                                        value={data.username}
                                        onChange={handleChange}
                                        error={!!errors.username}
                                    />
                                    {errors.username && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.username}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Password *</SoftTypography>
                                    <SoftInput
                                        // type={showPassword ? 'text' : 'password'}
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        value={data.password}
                                        onChange={handleChange}
                                        error={!!errors.password}
                                    // endAdornment={
                                    //     <Icon
                                    //         icon={showPassword ? eyeOff : eye}
                                    //         onClick={togglePasswordVisibility}
                                    //         style={{ cursor: 'pointer' }}
                                    //     />
                                    // }
                                    />
                                    {errors.password && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.password}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                <SoftBox mt={4} mb={1}>
                                    <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                                        Submit
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default CreateSuperadmin;