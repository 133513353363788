import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from 'components/SoftButton';
import SoftInput from "components/SoftInput";
import { Grid, Card } from '@mui/material';
import { APIData } from "authentication/APIData";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as AiIcons from "react-icons/ai";
import Swal from "sweetalert2";
import CustomSelect from "assets/theme/components/Select/CustomSelect";

const EditProfile = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [status, setStatus] = useState('');

    const qualificationOptions = [
        { value: "Masters Degree", label: "Master's Degree" },
        { value: "Bachelors Degree", label: "Bachelor's Degree" },
        { value: "PUC", label: "PUC" },
        { value: "SSLC", label: "SSLC" }
    ];

    const genderOptions = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Not Disclosed", label: "Not Disclosed" },
    ];

    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                const response = await fetch(`${APIData.api}employee/${id}`, { headers: APIData.headers });
                const data = await response.json();
                setEmployeeDetails(data);
                setStatus(data.status);
                console.log(data);

            } catch (error) {
                Swal.fire({
                    title: "Error!",
                    text: "Failed to fetch employee details",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        };

        fetchEmployeeData();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedEmployee = { ...employeeDetails, status };

            const response = await fetch(`${APIData.api}employee/`, {
                method: "POST",
                headers: {
                    ...APIData.headers,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedEmployee),
            });

            if (response.ok) {
                Swal.fire({
                    title: "Success!",
                    text: "Employee details updated successfully",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                navigate("/profile");
            } else {
                throw new Error("Failed to update employee details");
            }
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: error.message,
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployeeDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        if (selectedOption) {
            setEmployeeDetails(prevFormData => ({
                ...prevFormData,
                [actionMeta.name]: selectedOption.value,
            }));
        }
    };

    if (!employeeDetails) return <SoftTypography>Loading employee details...</SoftTypography>;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Grid item xs={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/profile">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Edit Profile {employeeDetails.name}
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox pt={1} pb={3} px={3}>
                            <form onSubmit={handleSubmit}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Full Name</SoftTypography>
                                    <SoftInput
                                        value={employeeDetails.name || ""}
                                        name="name"
                                        onChange={handleChange}
                                        size="small"
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Email</SoftTypography>
                                    <SoftInput
                                        value={employeeDetails.email || ""}
                                        name="email"
                                        onChange={handleChange}
                                        disabled
                                        size="small"
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Phone Number</SoftTypography>
                                    <SoftInput
                                        value={employeeDetails.phone_number || ""}
                                        name="phone_number"
                                        onChange={handleChange}
                                        size="small"
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Address</SoftTypography>
                                    <SoftInput
                                        value={employeeDetails.address || ""}
                                        name="address"
                                        onChange={handleChange}
                                        size="small"
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6" mb={1}>Gender</SoftTypography>
                                    <CustomSelect
                                        options={genderOptions}
                                        placeholder="Select your Gender"
                                        name="gender"
                                        onChange={handleSelectChange}
                                        value={employeeDetails.gender}
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Date of Birth</SoftTypography>
                                    <SoftInput
                                        value={employeeDetails.dob || ""}
                                        name="dob"
                                        onChange={handleChange}
                                        disabled
                                        size="small"
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Year of Appointment</SoftTypography>
                                    <SoftInput
                                        value={employeeDetails.year_of_appointment || ""}
                                        name="year_of_appointment"
                                        onChange={handleChange}
                                        disabled
                                        size="small"
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6" mb={1}>Qualification</SoftTypography>
                                    <CustomSelect
                                        options={qualificationOptions}
                                        placeholder="Select your qualification"
                                        name="qualification"
                                        onChange={handleSelectChange}
                                        value={employeeDetails.qualification}
                                    />
                                </SoftBox>


                                <SoftBox mt={2}>
                                    <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                                        Update Profile
                                    </SoftButton>
                                </SoftBox>
                            </form>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Footer />
        </DashboardLayout>
    );
};

export default EditProfile;
