import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { APIData, org } from '../authentication/APIData';
import {
    CircularProgress,
    Grid,
    Backdrop,
    Card,
    IconButton,
    Tooltip
} from "@mui/material";
import { HelpCenterRounded } from '@mui/icons-material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Footer from "examples/Footer";
import axios from "axios";
import Swal from "sweetalert2";

const ApplyCourse = () => {
    const { courseid } = useParams();
    const [courseDetails, setCourseDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [applyLoading, setApplyLoading] = useState(false);
    const capitalname = JSON.parse(localStorage.getItem("sessiondetails"))
    const currentDate = new Date().toISOString().split('T')[0];
  console.log(capitalname.user);
  
    useEffect(() => {
        const fetchCourseDetails = async () => {
            setLoading(true);
            try {
                const url = `${APIData.api}courses/${courseid}`;
                const response = await axios.get(url, { headers: APIData.headers });
                setCourseDetails(response.data);
            } catch (err) {
                setError(err.message);
                console.error("Error fetching course details", err);
            } finally {
                setLoading(false);
            }
        };

        if (courseid) {
            fetchCourseDetails();
        }
    }, [courseid]);

    const handleApply = async () => {
        setApplyLoading(true);
        const requestBody = {
            accept_status: "PENDING",
            user_email: capitalname.email,
            office_email: capitalname.email,
            enrollment_type: "STUDENT",
            enrollment_status: "APPLIED",
            release_status: "PENDING",
            course_id: courseDetails.course_id,
            job_id: "",
            admin_id: courseDetails.admin_id,
            user_name: capitalname.user,
            user_phone_number: capitalname.phoneNumber,
            followup_datetime: currentDate,
            enrolled_date: currentDate,
            new_enrolled_date: currentDate,
            branch: "",
            org: org,
            dept: "",
            designation: "",
            role: "",
            enrollmentSupervisor: "",
            deptSupervisor: "",
            prevDeptSupervisor: "",
            resume_url: "",
      
          };
          console.log(requestBody);
          
          try {
            const response = await axios.post(APIData.api + `enrollments/student?orgId=${org}`,requestBody, { headers: APIData.headers });
      
            if (response.status === 201 || response.status === 200) {
              const successMessage = response.data.description || "Successfully applied for the course!";
              console.log(response);
              Swal.fire({
                icon: "success",
                title: "Successfully Updated",
                text:successMessage,
                showConfirmButton: false,
                timer: 1500
            });

            } 
            else {
              Swal.fire({
                icon: "error",
                title: "Update Error",
                text: "Unexpected response: " + response.status
            });
            }
            setApplyLoading(false);
      
          } catch (error) {
            console.error(error);
            Swal.fire({
                icon: "error",
                title: "Update Error",
                text:"Failed to apply for the course."
            });
            setApplyLoading(false);
          } finally {
            setApplyLoading(false);
        }
    };

    if (loading) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SoftBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="calc(100vh - 100px)"
                >
                    <CircularProgress />
                   
                </SoftBox>
                <Footer />
            </DashboardLayout>
        );
    }

    if (error) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SoftBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="calc(100vh - 100px)"
                >
                    <SoftTypography color="error">
                        Error loading course details: {error}
                    </SoftTypography>
                </SoftBox>
                <Footer />
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={applyLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Grid item xs={12} sm={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Course Registration
                                <Tooltip title="If you registered already for other course you cant register again here">
                                    <IconButton edge="end" size="small">
                                        <HelpCenterRounded />
                                    </IconButton>
                                </Tooltip>
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={1} pb={3} px={3}>
                            <SoftBox component="form">
                            <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Mobile No</SoftTypography>
                                    <SoftInput
                                        value={capitalname.phoneNumber}
                                        fullWidth
                                        disabled
                                    />
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Name</SoftTypography>
                                    <SoftInput
                                        value={capitalname.user}
                                        fullWidth
                                        disabled
                                    />
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Email</SoftTypography>
                                    <SoftInput
                                        value={capitalname.email}
                                        fullWidth
                                        disabled
                                    />
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Name</SoftTypography>
                                    <SoftInput
                                        value={courseDetails?.course_id || ""}
                                        fullWidth
                                        disabled
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Sub Type</SoftTypography>
                                    <SoftInput
                                        value={courseDetails?.course_sub_type || ""}
                                        fullWidth
                                        disabled
                                    />
                                </SoftBox>
                            
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Fees</SoftTypography>
                                    <SoftInput
                                        value={courseDetails?.course_fees || ""}
                                        fullWidth
                                        disabled
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Duration</SoftTypography>
                                    <SoftInput
                                        value={courseDetails?.course_duration || ""}
                                        fullWidth
                                        disabled
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Description</SoftTypography>
                                    <SoftInput
                                        value={courseDetails?.course_description || ""}
                                        fullWidth
                                        multiline
                                        rows={1}
                                        disabled
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Syllabus</SoftTypography>
                                    <SoftInput
                                        value={courseDetails?.syallabus || ""}
                                        fullWidth
                                        multiline
                                        rows={4}
                                        disabled
                                    />
                                </SoftBox>

                                <SoftBox mt={4} mb={1} display="flex" justifyContent="center" gap={2}>
                                    <SoftButton
                                        variant="gradient"
                                        color="info"
                                        onClick={handleApply}
                                    >
                                        Apply
                                    </SoftButton>
                                    <SoftButton
                                        variant="gradient"
                                        color="primary"
                                        onClick={() => window.history.back()}
                                    >
                                        Back
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Footer />
        </DashboardLayout>
    );
};

export default ApplyCourse;