import React, { useEffect } from 'react';
import { select } from 'd3';
import { OrgChart } from 'd3-org-chart';

const data = [
  {
    "name": "Manjunath Anand",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "CTO",
    "id": "O-6067",
    "parentId": "O-6066"
  },
  {
    "name": "Charanya Vanditha",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "CTO",
    "id": "O-6068",
    "parentId": "O-6067"
  },
  {
    "name": "Nikil",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "CTO",
    "id": "O-6069",
    "parentId": "O-6067"
  },
  {
    "name": "Info/Admin",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "CTO",
    "id": "O-6070",
    "parentId": "O-6067"
  },
  {
    "name": "Hanish",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "Head of direction",
    "id": "O-6071",
    "parentId": "O-6069"
  },
  {
    "name": "Saurab",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "Head of laboratory",
    "id": "O-6072",
    "parentId": "O-6071"
  },
  {
    "name": "Poornima",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "Senior sales manager",
    "id": "O-6073",
    "parentId": "O-6072"
  },

  {
    "name": "Suresh",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "Senior Sales Manager",
    "id": "O-6074",
    "parentId": "O-6073"
  },
  {
    "name": "Ganesh",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "CTO",
    "id": "O-6075",
    "parentId": "O-6074"
  },
  {
    "name": "Sanjana",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "Senior Trader",
    "id": "O-6076",
    "parentId": "O-6074"
  },
  {
    "name": "Nagarjuna",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "Head of laboratory",
    "id": "O-6077",
    "parentId": "O-6074"
  },
  {
    "name": "Sachin",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "Head of laboratory",
    "id": "O-6078",
    "parentId": "O-6074"
  },
  {
    "name": "Baghirathi",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "Head of laboratory",
    "id": "O-6079",
    "parentId": "O-6074"
  },
  {
    "name": "Amulya",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CEO office",
    "positionName": "Head of laboratory",
    "id": "O-6080",
    "parentId": "O-6074"
  },
  {
    "name": "Care 4 Edu",
    "imageUrl": "https://care4edu.com/static/media/c4e.7a50dff3e32640f1939b.png",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CTO office",
    "positionName": "Organization",
    "id": "O-6066",
    "parentId": null
  }
];

const OrgHierarchy = () => {
  useEffect(() => {
    const chartContainer = select('.chart-container');

    if (!data.length) {
      console.error('No data found in JSON');
      return;
    }

    const chart = new OrgChart()
      .container('.chart-container')
      .data(data)
      .nodeWidth(() => 250)
      .initialZoom(0.7)
      .nodeHeight(() => 175)
      .childrenMargin(() => 40)
      .compactMarginBetween(() => 15)
      .compactMarginPair(() => 80)
      .nodeContent((d) => `
        <div style="padding-top:30px;background-color:none;margin-left:1px;height:${d.height}px;border-radius:2px;overflow:visible">
          <div style="height:${d.height - 32}px;padding-top:0px;background-color:white;border:1px solid lightgray;">
            <img src="${d.data.imageUrl}" style="margin-top:-30px;margin-left:${d.width / 2 - 30}px;border-radius:100px;width:60px;height:60px;" />
            <div style="margin-right:10px;margin-top:15px;float:right">${d.data.id}</div>
            <div style="margin-top:-30px;background-color:#3AB6E3;height:10px;width:${d.width - 2}px;border-radius:1px"></div>
            <div style="padding:20px; padding-top:35px;text-align:center">
              <div style="color:#111672;font-size:16px;font-weight:bold">${d.data.name}</div>
              <div style="color:#404040;font-size:16px;margin-top:4px">${d.data.positionName}</div>
            </div> 
            <div style="display:flex;justify-content:space-between;padding-left:15px;padding-right:15px;">
              <div> Manages: ${d.data._directSubordinates || 0} 👤</div>  
              <div> Oversees: ${d.data._totalSubordinates || 0} 👤</div>    
            </div>
          </div>     
        </div>
      `)
      .render();
  }, []);

  return (
    <div
      className="chart-container"
      style={{ height: '1200px', backgroundColor: '#f6f6f6' }}
    ></div>
  );
};

export default OrgHierarchy;


/*
import React, { useEffect, useState } from 'react';
import { select } from 'd3';
import { OrgChart } from 'd3-org-chart';
import axios from 'axios';
import { APIData, org } from 'authentication/APIData';

const OrgHierarchy = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${APIData.api}employee/details?org=${org}`, {
          headers: APIData.headers,
        });
        const transformedData = transformData(response.data); // Use response.data directly
        console.log(response.status);
        setData(transformedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const chartContainer = select('.chart-container');

    if (!data.length) {
      console.error('No data found in JSON');
      return;
    }

    const chart = new OrgChart()
      .container('.chart-container')
      .data(data)
      .nodeWidth(() => 250)
      .initialZoom(0.7)
      .nodeHeight(() => 175)
      .childrenMargin(() => 40)
      .compactMarginBetween(() => 15)
      .compactMarginPair(() => 80)
      .nodeContent((d) => `
        <div style="padding-top:30px;background-color:none;margin-left:1px;height:${d.height}px;border-radius:2px;overflow:visible">
          <div style="height:${d.height - 32}px;padding-top:0px;background-color:white;border:1px solid lightgray;">
            <img src="${d.data.photoUrl}" style="margin-top:-30px;margin-left:${d.width / 2 - 30}px;border-radius:100px;width:60px;height:60px;" />
            <div style="margin-right:10px;margin-top:15px;float:right">${d.data.employee_id}</div>
            <div style="margin-top:-30px;background-color:#3AB6E3;height:10px;width:${d.width - 2}px;border-radius:1px"></div>
            <div style="padding:20px; padding-top:35px;text-align:center">
              <div style="color:#111672;font-size:16px;font-weight:bold">${d.data.name}</div>
              <div style="color:#404040;font-size:16px;margin-top:4px">${d.data.designation.join(', ')}</div>
            </div> 
            <div style="display:flex;justify-content:space-between;padding-left:15px;padding-right:15px;">
              <div> Manages: ${d.data.supervisor.length || 0} 👤</div>  
            </div>
          </div>     
        </div>
      `)
      .render();
  }, [data]);

  const transformData = (jsonData) => {
    const orgData = [];
    const employeeMap = {};
  
    // Build the employee map and org data
    jsonData.forEach(employee => {
      const { employee_id, name, designation, photoUrl, supervisor } = employee;
      const orgEmployee = {
        id: employee_id,
        name,
        positionName: designation.join(', '),
        photoUrl,
        parentId: supervisor.length > 0 ? supervisor[0] : null,
      };
      employeeMap[employee_id] = orgEmployee;
      orgData.push(orgEmployee);
    });
  
    // Find the root node (for example, assuming the one with no supervisor is the root)
    const rootNodes = orgData.filter(employee => !employee.parentId);
  
    // If there are multiple root nodes, you might need to decide which one to keep
    if (rootNodes.length > 1) {
      console.warn('Multiple root nodes found:', rootNodes);
      // Example: choose the first root node for now
      const rootNode = rootNodes[0];
  
      // You can then decide how to handle the others, e.g., assigning them as children
      orgData.forEach(employee => {
        if (employee.parentId === null) {
          employee.parentId = rootNode.id; // Assign other roots under the chosen root
        }
      });
    }
  
    return orgData;
  };
  

  return (
    <div
      className="chart-container"
      style={{ height: '1200px', backgroundColor: '#f6f6f6' }}
    ></div>
  );
};

export default OrgHierarchy;

*/