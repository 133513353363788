import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { CircularProgress, Backdrop, Grid, Card } from "@mui/material";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import Swal from 'sweetalert2';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';

const Schedule = () => {
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));

    const [formData, setFormData] = useState({
        title: '',
        provEmailId: sessiondetails?.email || '', 
        custEmailId: '',
        description: '',
        startTime: '',
        endTime: '',
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [toOption, setToOption] = useState('');
    const [department, setDepartment] = useState('');
    const [emails, setEmails] = useState([]);
    const [externalEmails, setExternalEmails] = useState('');
    const [orgData, setOrgData] = useState(null);
    const [orgName, setOrgName] = useState('');
    const [orgAddr, setOrgAddr] = useState('');

    const toOptions = [
        { value: 'All', label: 'All' },
        { value: 'Department', label: 'Department' },
        { value: 'Others', label: 'Others' }
    ];

    const departmentOptions = [
        { value: 'TECHNICAL', label: 'TECHNICAL' },
        { value: 'SALES', label: 'SALES' },
        { value: 'FINANCE', label: 'FINANCE' },
        { value: 'HR', label: 'HR' },
        { value: 'ACADEMY', label: 'ACADEMY' }
    ];

    const validationRules = {
        provEmailId: (value) => {
            if (!value) return 'Provider Email is required';
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return 'Invalid email format';
            return '';
        },
        custEmailId: (value) => {
            if (!value && toOption === 'Others') return 'At least one recipient email is required';
            if (value) {
                const emails = value.split(',').map(email => email.trim());
                const invalidEmails = emails.filter(email => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
                if (invalidEmails.length > 0) return 'Invalid email format';
            }
            return '';
        },
        title: (value) => !value ? 'Subject is required' : '',
        description: (value) => !value ? 'Description is required' : '',
        startTime: (value) => {
            if (!value) return 'Start time is required';
            if (new Date(value) < new Date()) return 'Start time must be in the future';
            return '';
        },
        endTime: (value) => {
            if (!value) return 'End time is required';
            if (new Date(value) <= new Date(formData.startTime)) return 'End time must be after start time';
            return '';
        },
    };

    useEffect(() => {
        fetchOrgDetails();
        fetchEmails();
    }, [toOption, department]);

    const fetchEmails = async () => {
        if (toOption !== 'Others') {
            try {
                let url = APIData.api;
                if (toOption === 'All') {
                    url += `employee/emails?org=${org}`;
                } else if (toOption === 'Department' && department) {
                    url += `employee/email?department=${department}&org=${org}`;
                } else {
                    return;
                }

                const response = await axios.get(url, { headers: APIData.headers });
                setEmails(response.data);
                setFormData(prev => ({
                    ...prev,
                    custEmailId: response.data.join(', ')
                }));
            } catch (error) {
                console.error('Error fetching emails:', error);
                toast.error('Failed to fetch emails');
            }
        }
    };

    const fetchOrgDetails = async () => {
        try {
            const response = await axios.get(APIData.api + `org-mdm/org-id?orgId=${org}`, {
                headers: APIData.headers
            });
            setOrgData(response.data);
            setOrgName(response.data.orgName);
            setOrgAddr(response.data.orgAddress);
        } catch (error) {
            console.error('Error fetching org details:', error);
            setOrgName(APIData.orgName);
            setOrgAddr('');
            toast.error('Failed to fetch organization details');
        }
    };

    const handleToChange = (selectedOption) => {
        setToOption(selectedOption.value);
        setDepartment('');
        setEmails([]);
        setFormData(prev => ({
            ...prev,
            custEmailId: ''
        }));
    };

    const handleDepartmentChange = (selectedOption) => {
        setDepartment(selectedOption.value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));

        if (validationRules[name]) {
            const error = validationRules[name](value);
            setErrors(prev => ({ ...prev, [name]: error }));
        }
    };

    const validateFields = () => {
        const newErrors = {};
        Object.keys(validationRules).forEach(field => {
            const error = validationRules[field](formData[field] || '');
            if (error) newErrors[field] = error;
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const formatDateWithTimezone = (dateString) => {
        const date = new Date(dateString);
        
        if (isNaN(date.getTime())) {
          console.error("Invalid date value");
          return dateString;
        }
      
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hourCycle: 'h23',
          timeZone: 'Asia/Kolkata',
        }).format(date);
      
        return `${formattedDate.slice(6, 10)}-${formattedDate.slice(3, 5)}-${formattedDate.slice(0, 2)}T${formattedDate.slice(11)}+05:30`.replace("T ", "T");
      };
      const handleSubmit = async (e) => {
          e.preventDefault();
          setLoading(true);
      
          if (!validateFields()) {
              toast.error("Please fill in all required fields correctly.");
              setLoading(false);
              return;
          }
          const internalEmails = formData.custEmailId ? formData.custEmailId.split(',').map((email) => email.trim()) : [];
          const externalEmailList = externalEmails ? externalEmails.split(',').map((email) => email.trim()) : [];
          const allEmails = [...new Set([...internalEmails, ...externalEmailList])];
      
          if (allEmails.length === 0) {
              toast.error("At least one recipient email is required");
              setLoading(false);
              return;
          }

          const formattedData = {
              title: formData.title,
              provEmailId: formData.provEmailId.trim(),
              custEmailId: allEmails,
              description: formData.description,
              startTime: formatDateWithTimezone(formData.startTime),
              endTime: formatDateWithTimezone(formData.endTime),
          };
      
          try {
              const url = APIData.tpapi + 'web-conf/meeting';
              const headers = {
                  'Content-Type': 'application/json',
                  'orgName': orgName,
                  'orgAddr': orgAddr,
                  ...APIData.tpheaders,
              };
      
              const response = await axios.post(url, formattedData, { headers });
              console.log(response);
                   
              Swal.fire({
                  icon: "success",
                  title: "Meeting scheduled successfully",
                  showConfirmButton: false,
                  timer: 1500
              });
              setFormData({
                  title: '',
                  provEmailId: sessiondetails?.email || '',
                  custEmailId: '',
                  description: '',
                  startTime: '',
                  endTime: '',
              });
              setExternalEmails('');
              setToOption('All');
              setDepartment('');
      
          } catch (error) {
              console.error('Error scheduling meeting:', error);
              
              Swal.fire({
                  icon: "error",
                  title: "Failed to schedule meeting",
                  text: error.response?.data?.message || "An unexpected error occurred",
              });
          } finally {
              setLoading(false);
          }
      };
    return (
        <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
        <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12} sm={8}>
          <Card sx={{ height: 'auto' }}>
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Link to="/dashboard">
                <AiIcons.AiOutlineCloseCircle />
              </Link>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                Schedule Meeting
              </SoftTypography>
            </SoftBox>

            <SoftBox pt={1} pb={3} px={3}>
              <SoftBox component="form" onSubmit={handleSubmit}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6">To *</SoftTypography>
                  <CustomSelect
                    options={toOptions}
                    placeholder="Select Recipients"
                    value={toOptions.find(option => option.value === toOption)}
                    onChange={handleToChange}
                  />
                </SoftBox>

                {toOption === 'Department' && (
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6">Department *</SoftTypography>
                    <CustomSelect
                      options={departmentOptions}
                      placeholder="Select Department"
                      value={departmentOptions.find(option => option.value === department)}
                      onChange={handleDepartmentChange}
                    />
                  </SoftBox>
                )}

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Organization Email IDs</SoftTypography>
                  <SoftInput
                    placeholder="Organization Email IDs"
                    name="custEmailId"
                    value={formData.custEmailId}
                    onChange={handleChange}
                    multiline
                    rows={3}
                    disabled={toOption === 'Department' && emails.length > 0}
                  />
                  <SoftTypography variant="caption" color="text">
                    Email IDs should be comma separated
                  </SoftTypography>
                  {errors.custEmailId && (
                    <SoftTypography variant="caption" color="error">
                      {errors.custEmailId}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">External Email IDs</SoftTypography>
                  <SoftInput
                    placeholder="External Email IDs (comma separated)"
                    value={externalEmails}
                    onChange={(e) => setExternalEmails(e.target.value)}
                    multiline
                    rows={1}
                  />
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Provider Email ID *</SoftTypography>
                  <SoftInput
                    placeholder="Provider Email ID"
                    name="provEmailId"
                    value={formData.provEmailId}
                    onChange={handleChange}
                    error={!!errors.provEmailId}
                  />
                  {errors.provEmailId && (
                    <SoftTypography variant="caption" color="error">
                      {errors.provEmailId}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Organization Name *</SoftTypography>
                  <SoftInput
                    placeholder="Organization Name"
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    error={!!errors.orgName}
                  />
                  {errors.orgName && (
                    <SoftTypography variant="caption" color="error">
                      {errors.orgName}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Organization Address *</SoftTypography>
                  <SoftInput
                    placeholder="Organization Address"
                    value={orgAddr}
                    onChange={(e) => setOrgAddr(e.target.value)}
                    error={!!errors.orgAddr}
                  />
                  {errors.orgAddr && (
                    <SoftTypography variant="caption" color="error">
                      {errors.orgAddr}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Subject *</SoftTypography>
                  <SoftInput
                    placeholder="Meeting Subject"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    error={!!errors.title}
                  />
                  {errors.title && (
                    <SoftTypography variant="caption" color="error">
                      {errors.title}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Description *</SoftTypography>
                  <SoftInput
                    placeholder="Meeting Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    multiline
                    rows={3}
                    error={!!errors.description}
                  />
                  {errors.description && (
                    <SoftTypography variant="caption" color="error">
                      {errors.description}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Start Time *</SoftTypography>
                  <SoftInput
                    type="datetime-local"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleChange}
                    error={!!errors.startTime}
                  />
                  {errors.startTime && (
                    <SoftTypography variant="caption" color="error">
                      {errors.startTime}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">End Time *</SoftTypography>
                  <SoftInput
                    type="datetime-local"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleChange}
                    error={!!errors.endTime}
                  />
                  {errors.endTime && (
                    <SoftTypography variant="caption" color="error">
                      {errors.endTime}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mt={4} mb={1}>
                  <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                    Schedule Meeting
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
      <ToastContainer />
    </DashboardLayout>
    );
};

export default Schedule;