// /**
// =========================================================
// * Soft UI Dashboard React - v4.0.1
// =========================================================

// * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */


// import { useState, useEffect } from "react";
// import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftAvatar from "components/SoftAvatar";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import breakpoints from "assets/theme/base/breakpoints";
// import maleProfileImage from "./User-Profile-PNG-Picture.png";
// import femaleProfileImage from "./-avatar-people-person-profile-user-women-icon--icon-search-engine-23.png";
// import undisclosedProfileImage from "./png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-removebg-preview.png";
// import curved0 from "assets/images/curved-images/curved0.jpg";
// import Swal from "sweetalert2";
// import { org } from "authentication/APIData";
// import { APIData } from "authentication/APIData";

// function Header() {
//   const [tabsOrientation, setTabsOrientation] = useState("horizontal");
//   const [profileInfo, setProfileData] = useState({});
//   const [tabValue, setTabValue] = useState(0);
//   const [userdata, setUserData] = useState();

//   useEffect(() => {

//     function handleTabsOrientation() {
//       return window.innerWidth < breakpoints.values.sm
//         ? setTabsOrientation("vertical")
//         : setTabsOrientation("horizontal");
//     }

//     window.addEventListener("resize", handleTabsOrientation);

//     handleTabsOrientation();

//     return () => window.removeEventListener("resize", handleTabsOrientation);
//   }, [tabsOrientation]);

//   const handleSetTabValue = (event, newValue) => setTabValue(newValue);

//   useEffect(() => {
//     const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
//     setUserData(userData_Local);
//     console.log(userData_Local);
//   }, []);

//   useEffect(() => {
//     if (userdata) {
//       const fetchData = async () => {
//         try {
//           let url = `${APIData.api}employee/${userdata.userName}`;
//           const response = await fetch(url, { headers: APIData.headers });
//           console.log(response);

//           const data = await response.json();
//           setProfileData(data);
//         } catch (error) {
//           Swal.fire({
//             title: "Error!",
//             text: `Failed to fetch "${org}" details`,
//             icon: "error",
//             confirmButtonText: "OK",
//           });
//         }
//       };

//       fetchData();
//     }
//   }, [userdata]);

//   return (
//     <SoftBox position="relative">
//       <DashboardNavbar absolute light />
//       <SoftBox
//         display="flex"
//         alignItems="center"
//         position="relative"
//         minHeight="18.75rem"
//         borderRadius="xl"
//         sx={{
//           backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
//             `${linearGradient(
//               rgba(gradients.info.main, 0.6),
//               rgba(gradients.info.state, 0.6)
//             )}, url(${curved0})`,
//           backgroundSize: "cover",
//           backgroundPosition: "50%",
//           overflow: "hidden",
//         }}
//       />
//       <Card
//         sx={{
//           backdropFilter: `saturate(200%) blur(30px)`,
//           backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
//           boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
//           position: "relative",
//           mt: -8,
//           mx: 3,
//           py: 2,
//           px: 2,
//         }}
//       >
//         <Grid container spacing={3} alignItems="center">
//           <Grid item>
//             <SoftAvatar
//               src={
//                 profileInfo.photoUrl ||
//                 (profileInfo.gender === "Male"
//                   ? maleProfileImage
//                   : profileInfo.gender === "Female"
//                   ? femaleProfileImage
//                   : undisclosedProfileImage)
//               }
//               alt="profile-image"
//               variant="rounded"
//               size="xl"
//               shadow="sm"
//               sx={{ objectFit: "cover", position: "relative" }}
//             />
//           </Grid>
//           <Grid item>
//             <SoftBox height="100%" mt={0.5} lineHeight={1}>
//               <SoftTypography variant="h5" fontWeight="medium">
//                 {profileInfo.name ? profileInfo.name.charAt(0).toUpperCase() + profileInfo.name.slice(1).toLowerCase() : "Name"}
//               </SoftTypography>
//               <SoftTypography variant="button" color="text" fontWeight="medium">
//                 {profileInfo.emy_type} / {profileInfo.dept}
//               </SoftTypography>
//             </SoftBox>
//           </Grid>
//           <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
//             {/* <AppBar position="static">
//               <Tabs
//                 orientation={tabsOrientation}
//                 value={tabValue}
//                 onChange={handleSetTabValue}
//                 sx={{ background: "transparent" }}
//               >
//                 <Tab label="App" icon={<Cube />} />
//                 <Tab label="Message" icon={<Document />} />
//                 <Tab label="Settings" icon={<Settings />} />
//               </Tabs>
//             </AppBar> */}
//           </Grid>
//         </Grid>
//       </Card>
//     </SoftBox>
//   );
// }

// export default Header;



import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import maleProfileImage from "./User-Profile-PNG-Picture.png";
import femaleProfileImage from "./-avatar-people-person-profile-user-women-icon--icon-search-engine-23.png";
import undisclosedProfileImage from "./png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-removebg-preview.png";
import image from "assets/images/curved-images/2b574b11b0fb509db1531bd8bfcc9f3b.jpg";
import Swal from "sweetalert2";
import { org } from "authentication/APIData";
import { APIData } from "authentication/APIData";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import SoftButton from "components/SoftButton";

function Header() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [profileInfo, setProfileData] = useState({});
  const [userdata, setUserData] = useState();
  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orgLogo, setOrgLogo] = useState();
  const [orgShortName, setOrgShortName] = useState("");

  useEffect(() => {
    const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
    setUserData(userData_Local);
  }, []);

  useEffect(()=>{
    const fetchOrgData = async () => {
      try {
        const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
        if (!response.ok) {
          throw new Error("Failed to fetch organization data");
        }
        const data = await response.json();
        console.log(data);
        setOrgLogo(data.orgLogo);
        setOrgShortName(data.orgName);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };
    fetchOrgData()
  },[org])

  useEffect(() => {
    if (userdata) {
      const fetchData = async () => {
        try {
          let url = `${APIData.api}employee/${userdata.userName}`;
          const response = await fetch(url, { headers: APIData.headers });
          const data = await response.json();
          setProfileData(data);
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: `Failed to fetch "${org}" details`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      };

      fetchData();
    }
  }, [userdata]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClickEditOpen = () => setOpenEditDialog(true);
  const handleEditClose = () => setOpenEditDialog(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSaveImage = async () => {
    if (!selectedFile) {
      Swal.fire({
        title: "Error!",
        text: "No file selected",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("id", profileInfo.employee_id);
    formData.append("file", selectedFile);

    try {
      const response = await fetch(`${APIData.api}employee/${profileInfo.employee_id}/image`, {
        method: "POST",
        headers: APIData.headers,
        body: formData,
      });
      const data = await response.json();

      if (response.ok) {
        setProfileData({ ...profileInfo, photoUrl: data.photoUrl });
        setOpenEditDialog(false);
        setOpen(false);
        setImagePreview(null);
        setSelectedFile(null);
        fetchProfileData();
        Swal.fire({
          title: "Success!",
          text: "Profile image updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        })

      } else {
        Swal.fire({
          title: "Error!",
          text: data.message || "Failed to update image",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong, please try again",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch profile data to update the profile image
  const fetchProfileData = async () => {
    try {
      const url = `${APIData.api}employee/${userdata.userName}`;
      const response = await fetch(url, { headers: APIData.headers });
      const data = await response.json();
      setProfileData(data); // Update the profile info with the new image URL
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: `Failed to fetch "${org}" details`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };



  const handleCancelEdit = () => {
    setSelectedFile(null);
    setImagePreview(null);
    setOpenEditDialog(false);
  };

  return (
    <SoftBox position="relative">
      <DashboardNavbar absolute light />
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        {/* <SoftBox
          component="img"
          src={orgLogo}
          alt="Logo"
          sx={{
            position: "absolute",
            top: "25px",
            left: "25px",
            width: "200px",
            height: "200px",
            zIndex: 1,
          }}
        />
        {orgShortName && (
          <SoftTypography
            variant="h6"
            fontWeight="medium"
            color="white"
            sx={{
              position: "absolute",
              top: "240px",
              left: "25px",
              zIndex: 1,
            }}
          >
            {orgShortName}
          </SoftTypography>
        )} */}
        <SoftBox
  sx={{
    display: 'flex',
    alignItems: 'center',
    position: "absolute",
    top: "25px",
    left: "25px",
    zIndex: 1,
  }}
>
  <SoftBox
    component="img"
    src={orgLogo}
    alt="Logo"
    sx={{
      width: "180px",
      height: "180px",
      marginRight: "10px",
    }}
  />
  {orgShortName && (
    <SoftTypography
      variant="h6"
      fontWeight="medium"
      color="dark"
      sx={{
        fontSize: '2rem',
      }}
    >
      {orgShortName}
    </SoftTypography>
  )}
</SoftBox>
      </SoftBox>

      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar
              src={
                profileInfo.photoUrl ||
                (profileInfo.gender === "Male"
                  ? maleProfileImage
                  : profileInfo.gender === "Female"
                    ? femaleProfileImage
                    : undisclosedProfileImage)
              }
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
              sx={{ objectFit: "contain", cursor: "pointer" }} // Add cursor pointer
              onClick={handleClickOpen} // Open dialog on click
            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {profileInfo.name
                  ? profileInfo.name.charAt(0).toUpperCase() + profileInfo.name.slice(1).toLowerCase()
                  : "Name"}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {profileInfo.emy_type}
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </Card>

      {/* Profile Image Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogContent sx={{ p: 0 }}>
          <img
            src={
              profileInfo.photoUrl ||
              (profileInfo.gender === "Male"
                ? maleProfileImage
                : profileInfo.gender === "Female"
                  ? femaleProfileImage
                  : undisclosedProfileImage)
            }
            alt="Full-size profile"
            style={{ width: "100%", height: "auto" }}
          />
          <Tooltip title="Edit Profile Picture" arrow>
            <IconButton
              onClick={handleClickEditOpen}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </DialogContent>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleEditClose} maxWidth="sm">
        <DialogContent sx={{ p: 3 }}>
          <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-image"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="upload-image">
              <SoftButton variant="contained" component="span">
                Upload Image
              </SoftButton>
            </label>
            {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: "60%", height: "60%" }} />}
          </SoftBox>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <SoftButton variant="gradient" color="secondary" onClick={handleCancelEdit}>Cancel</SoftButton>
            </Grid>
            <Grid item>
              <SoftButton variant="gradient" color="success" onClick={handleSaveImage} disabled={isLoading}>
                {isLoading ? "Saving..." : "Save"}
              </SoftButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </SoftBox>
  );
}

export default Header;
