import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import {
  Card,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import { APIData, org } from "authentication/APIData";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as AiIcons from "react-icons/ai";

function AddAssetByGroup() {
  const { id } = useParams();
  const [assetName, setAssetName] = useState("");
  const [assetOwnerEmail, setAssetOwnerEmail] = useState("");
  const [assetOwnerName, setAssetOwnerName] = useState("");
  const [cost, setCost] = useState("");
  const [assetStatus, setAssetStatus] = useState("");
  const [productId, setProductId] = useState("");
  const [purchasedDate, setPurchasedDate] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [assetType, setAssetType] = useState("");

  const navigate = useNavigate();

  const fetchAssetGroupList = async () => {
    try {
      setLoading(true);
      const url = `${APIData.api}assetgroup/id?assetgroupid=${id}`;
      const response = await axios.get(url, { headers: APIData.headers });
      setAssetType(response.data.type);
    } catch (err) {
      console.error(
        "Error fetching asset group data:",
        err.response ? err.response.data : err.message
      );
      Swal.fire({
        icon: "error",
        title: "Failed to fetch asset group details",
        text: "Please check the asset group ID or try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssetGroupList();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
  
    if (!assetName) newErrors.assetName = "Asset name is required.";
    if (!assetOwnerEmail) newErrors.assetOwnerEmail = "Asset owner email is required.";
    if (!assetOwnerName) newErrors.assetOwnerName = "Asset owner name is required.";
    if (!cost) newErrors.cost = "Cost is required.";
    if (!assetStatus) newErrors.assetStatus = "Asset status is required.";
    if (!productId) newErrors.productId = "Product ID is required.";
    if (!purchasedDate) newErrors.purchasedDate = "Purchased date is required.";
    if (!assetType) newErrors.assetType = "Asset type is required.";
  
    if (purchasedDate) {
      const selectedDate = new Date(purchasedDate);
      const currentDate = new Date();
      selectedDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);
      if (selectedDate > currentDate) {
        newErrors.purchasedDate = "Purchased date cannot be a future date.";
      }
    }
  
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
  
        const formattedPurchasedDate = new Date(purchasedDate).toISOString().slice(0, 16);
        const AssetListData = {
          allocation: "Not Allocated",
          asset_group_id: id,
          asset_id: "",
          asset_name: assetName,
          asset_owner_email: assetOwnerEmail,
          asset_owner_name: assetOwnerName,
          asset_status: assetStatus,
          cost: parseInt(cost),
          product_id: productId,
          purchased_date: formattedPurchasedDate,
          renewed_date: "",
          type: assetType,
          org: org,
        };
  
        console.log(AssetListData);
  
        const url = `${APIData.api}asset-list/`;
        const response = await axios.post(url, AssetListData, { headers: APIData.headers });
        console.log(response);
  
        Swal.fire({
          icon: "success",
          title: "Asset Added Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
  
        setTimeout(() => {
          navigate(`/single-group-asset-list/${id}`);
        }, 1500);
      } catch (error) {
        console.error("Error details:", error.response ? error.response.data : error.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to add asset",
          footer: error.message,
        });
        setLoading(false);
      }
    }
  };  


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid
        container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4"
      >
        <Grid item xs={10} sm={10} md={8} lg={6}>
          <Card className="w-full">
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Tooltip title="Close" arrow>
                <Link to={`/single-group-asset-list/${id}`}>
                  <AiIcons.AiOutlineCloseCircle />
                </Link>
              </Tooltip>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                Add an Asset
              </SoftTypography>
            </SoftBox>
            <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
              <SoftBox mb={2}>
                <SoftTypography variant="h6">Asset Name *</SoftTypography>
                <FormControl fullWidth error={!!errors.assetName}>
                  <SoftInput
                    placeholder="Asset Name"
                    value={assetName}
                    onChange={(e) => setAssetName(e.target.value)}
                  />
                  {errors.assetName && <FormHelperText>{errors.assetName}</FormHelperText>}
                </FormControl>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="h6">Cost *</SoftTypography>
                <FormControl fullWidth error={!!errors.cost}>
                  <SoftInput
                    type="number"
                    placeholder="Cost"
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                  />
                  {errors.cost && <FormHelperText>{errors.cost}</FormHelperText>}
                </FormControl>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="h6">Product Id *</SoftTypography>
                <FormControl fullWidth error={!!errors.productId}>
                  <SoftInput
                    placeholder="Product ID"
                    value={productId}
                    onChange={(e) => setProductId(e.target.value)}
                  />
                  {errors.productId && <FormHelperText>{errors.productId}</FormHelperText>}
                </FormControl>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="h6">Asset Owner Email *</SoftTypography>
                <FormControl fullWidth error={!!errors.assetOwnerEmail}>
                  <SoftInput
                    placeholder="Asset Owner Email"
                    value={assetOwnerEmail}
                    onChange={(e) => setAssetOwnerEmail(e.target.value)}
                  />
                  {errors.assetOwnerEmail && <FormHelperText>{errors.assetOwnerEmail}</FormHelperText>}
                </FormControl>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="h6">Asset Owner Name *</SoftTypography>
                <FormControl fullWidth error={!!errors.assetOwnerName}>
                  <SoftInput
                    placeholder="Asset Owner Name"
                    value={assetOwnerName}
                    onChange={(e) => setAssetOwnerName(e.target.value)}
                  />
                  {errors.assetOwnerName && <FormHelperText>{errors.assetOwnerName}</FormHelperText>}
                </FormControl>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="h6">Asset Status *</SoftTypography>
                <FormControl fullWidth error={!!errors.assetStatus}>
                  <InputLabel id="asset-status-label"></InputLabel>
                  <Select
                    labelId="asset-status-label"
                    value={assetStatus}
                    onChange={(e) => setAssetStatus(e.target.value)}
                    name="assetStatus"
                    displayEmpty
                  >
                    <MenuItem value="">Select Asset Status</MenuItem>
                    <MenuItem value="Working">Working</MenuItem>
                    <MenuItem value="Not Working">Not Working</MenuItem>
                    <MenuItem value="In Repair">In Repair</MenuItem>
                  </Select>
                  {errors.assetStatus && <FormHelperText>{errors.assetStatus}</FormHelperText>}
                </FormControl>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="h6">Purchased Date *</SoftTypography>
                <FormControl fullWidth error={!!errors.purchasedDate}>
                  <SoftInput
                    type="date"
                    placeholder="Purchased Date"
                    value={purchasedDate}
                    onChange={(e) => setPurchasedDate(e.target.value)}
                  />
                  {errors.purchasedDate && <FormHelperText>{errors.purchasedDate}</FormHelperText>}
                </FormControl>
              </SoftBox>

              <SoftBox mt={4} mb={1}>
                <SoftButton type="submit" variant="gradient" color="info" fullWidth disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AddAssetByGroup;