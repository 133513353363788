// import PropTypes from "prop-types";
// import Menu from "@mui/material/Menu";
// import SoftBox from "components/SoftBox";
// import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";

// function DefaultNavbarMobile({ open, close }) {
//   const { width } = open && open.getBoundingClientRect();

//   return (
//     <Menu
//       getContentAnchorEl={null}
//       anchorOrigin={{
//         vertical: "bottom",
//         horizontal: "center",
//       }}
//       transformOrigin={{
//         vertical: "top",
//         horizontal: "center",
//       }}
//       anchorEl={open}
//       open={Boolean(open)}
//       onClose={close}
//       MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
//     >
//       <SoftBox px={0.5}>
//         <DefaultNavbarLink icon="donut_large" name="User SignIn" route="/dashboard" />
//         <DefaultNavbarLink icon="person" name="Org SignIn" route="/profile" />
//         <DefaultNavbarLink icon="account_circle" name="sign up" route="/authentication/sign-up" />
//         <DefaultNavbarLink icon="donut_large" name="Support" route="/dashboard" />
//         <DefaultNavbarLink icon="person" name="Careers" route="/profile" />
//         {/* <DefaultNavbarLink icon="account_circle" name="Courses" route="/authentication/sign-up" /> */}
//       </SoftBox>
//     </Menu>
//   );
// }

// // Typechecking props for the DefaultNavbarMenu
// DefaultNavbarMobile.propTypes = {
//   open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
//   close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
// };

// export default DefaultNavbarMobile;

import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SoftBox from "components/SoftBox";
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import { useNavigate } from 'react-router-dom';

function DefaultNavbarMobile({ open, close }) {
  const { width } = open && open.getBoundingClientRect();

  const navigate = useNavigate();

  const handleNavigation = (route) => {
    close(); 
    navigate(route); 
  };
  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",  // Align with the left edge of the icon
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",  // Align the menu starting at the top-left corner

      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      // Limit width
      MenuListProps={{
        style: {
          width: '250px', // Set fixed width for the menu
        },
      }}
    >
      <SoftBox px={0.5}>
        <MenuItem onClick={() => handleNavigation("/authentication/sign-in")} style={{ justifyContent: 'flex-start' }}>
          <DefaultNavbarLink icon="donut_large" name="User SignIn" />
        </MenuItem>
        <MenuItem onClick={() => handleNavigation("/authentication/org-sign-in")} style={{ justifyContent: 'flex-start' }}>
          <DefaultNavbarLink icon="person" name="Org SignIn" />
        </MenuItem>
        <MenuItem onClick={() => handleNavigation("/authentication/sign-up")} style={{ justifyContent: 'flex-start' }}>
          <DefaultNavbarLink icon="account_circle" name="Sign Up" />
        </MenuItem>
        <MenuItem onClick={() => handleNavigation("/customer-sign-in")} style={{ justifyContent: 'flex-start' }}>
          <DefaultNavbarLink icon="donut_large" name="Support" />
        </MenuItem>
        <MenuItem onClick={() => handleNavigation("/userJobs")} style={{ justifyContent: 'flex-start' }}>
          <DefaultNavbarLink icon="person" name="Careers" />
        </MenuItem>
      </SoftBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
