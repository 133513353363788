// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import AppBar from "@mui/material/AppBar";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import { Tooltip, CircularProgress } from "@mui/material";
// import SoftTypography from "components/SoftTypography";
// import SoftBox from "components/SoftBox";
// import SoftButton from "components/SoftButton";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { APIData, org } from 'authentication/APIData';
// import { useNavigate } from "react-router-dom";
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';

// const FestivalCard = ({ festival }) => {
//     const [isHovered, setIsHovered] = useState(false);
//     const [userdata, setUserData] = useState(null);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
//         setUserData(userData_Local);
//     }, []);

//     const getButtonVisibility = () => {
//         if (!userdata) {
//             return { showEditButton: false, showDeleteButton: false };
//         }
//         const userType = userdata.userType?.toLowerCase();
//         return {
//             showEditButton: userType === 'superadmin' || userType === 'employee',
//             showDeleteButton: userType === 'superadmin'
//         };
//     };

//     const { showEditButton, showDeleteButton } = getButtonVisibility();

//     const handleEdit = () => {
//         const url = `${APIData.api}festivals/updateFestivalImageDetails?id=${festival.id}`;
//         const formData = new FormData();
//         formData.append('fest_name', festival.festival_name);
//         formData.append('fest_date', festival.festival_date);
//         formData.append('des', festival.description);
//         formData.append('org', festival.org);

//         axios.put(url, formData, {
//             headers: { ...APIData.headers, 'Content-Type': 'multipart/form-data' }
//         })
//             .then(response => {
//                 if (response.status === 201 || response.status === "success") {
//                     Swal.fire({
//                         icon: 'success',
//                         title: 'Updated successfully'
//                     });
//                     window.location.reload();
//                 } else {
//                     Swal.fire({
//                         icon: 'error',
//                         title: 'Update failed',
//                         text: 'Please check with the Admin'
//                     });
//                 }
//             })
//             .catch(error => {
//                 Swal.fire({
//                     icon: 'error',
//                     title: 'Update failed',
//                     text: error.message
//                 });
//             });
//     };

//     const handleDelete = () => {
//         Swal.fire({
//             title: 'Are you sure?',
//             text: "You won't be able to revert this!",
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#3085d6',
//             cancelButtonColor: '#d33',
//             confirmButtonText: 'Yes, delete it!'
//         }).then((result) => {
//             if (result.isConfirmed) {
//                 const url = `${APIData.api}festivals/?id=${festival.id}`;
//                 axios.delete(url, { headers: APIData.headers })
//                     .then((response) => {
//                         if (response.status === 200) {
//                             Swal.fire(
//                                 'Deleted!',
//                                 'Festival has been deleted.',
//                                 'success'
//                             );
//                             window.location.reload();
//                         }
//                     })
//                     .catch((error) => {
//                         Swal.fire({
//                             icon: 'error',
//                             title: 'Delete failed',
//                             text: error.message
//                         });
//                     });
//             }
//         });
//     };

//     const cardStyle = {
//         height: "100%",
//         display: "flex",
//         flexDirection: "column",
//         transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
//         transform: isHovered ? "translateY(-8px)" : "none",
//         boxShadow: isHovered
//             ? "0 10px 20px rgba(0,0,0,0.2)"
//             : "0 2px 4px rgba(0,0,0,0.1)",
//     };

//     const imageContainerStyle = {
//         position: "relative",
//         paddingTop: "56.25%",
//         overflow: "hidden",
//     };

//     const imageStyle = {
//         position: "absolute",
//         top: 0,
//         left: 0,
//         width: "100%",
//         height: "100%",
//         objectFit: "cover",
//         transition: "transform 0.3s ease-in-out",
//         transform: isHovered ? "scale(1.05)" : "scale(1)",
//     };

//     const overlayStyle = {
//         position: "absolute",
//         top: 0,
//         left: 0,
//         right: 0,
//         bottom: 0,
//         background: "linear-gradient(to top, rgba(0,0,0,0.6) 0%, transparent 100%)",
//     };

//     const actionButtonsStyle = {
//         position: "absolute",
//         top: 8,
//         right: 8,
//         display: "flex",
//         gap: "8px",
//         opacity: isHovered ? 1 : 0,
//         transition: "opacity 0.3s ease-in-out",
//     };

//     return (
//         <Card
//             sx={cardStyle}
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)}
//         >
//             <div style={imageContainerStyle}>
//                 <img
//                     src={festival.image_url}
//                     alt={festival.festival_name}
//                     style={imageStyle}
//                     onError={(e) => {
//                         e.target.src = "/api/placeholder/400/320";
//                     }}
//                 />
//                 <div style={overlayStyle} />

//                 <div style={actionButtonsStyle}>
//                     {showEditButton && (
//                         <Tooltip title="Edit Festival">
//                             <SoftButton
//                                 size="small"
//                                 color="info"
//                                 variant="contained"
//                                 onClick={handleEdit}
//                                 sx={{
//                                     minWidth: '32px',
//                                     height: '32px',
//                                     p: 1,
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center'
//                                 }}
//                             >
//                                 <EditIcon sx={{ fontSize: 18 }} />
//                             </SoftButton>
//                         </Tooltip>
//                     )}

//                     {showDeleteButton && (
//                         <Tooltip title="Delete Festival">
//                             <SoftButton
//                                 size="small"
//                                 color="error"
//                                 variant="contained"
//                                 onClick={handleDelete}
//                                 sx={{
//                                     minWidth: '32px',
//                                     height: '32px',
//                                     p: 1,
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center'
//                                 }}
//                             >
//                                 <DeleteIcon sx={{ fontSize: 18 }} />
//                             </SoftButton>
//                         </Tooltip>
//                     )}
//                 </div>
//             </div>

//             <SoftBox p={2} flex={1} sx={{ position: "relative" }}>
//                 <SoftTypography
//                     variant="h5"
//                     fontWeight="bold"
//                     textTransform="capitalize"
//                     mb={1}
//                     sx={{
//                         transition: "color 0.3s ease",
//                         color: isHovered ? "info.main" : "text.primary"
//                     }}
//                 >
//                     {festival.festival_name}
//                 </SoftTypography>

//                 <SoftTypography
//                     variant="body2"
//                     color="text"
//                     mb={2}
//                 >
//                     {new Date(festival.festival_date).toLocaleDateString("en-US", {
//                         year: "numeric",
//                         month: "long",
//                         day: "numeric",
//                     })}
//                 </SoftTypography>

//                 <SoftTypography
//                     variant="body2"
//                     color="text"
//                     sx={{
//                         display: "-webkit-box",
//                         WebkitLineClamp: 3,
//                         WebkitBoxOrient: "vertical",
//                         overflow: "hidden",
//                         textOverflow: "ellipsis",
//                     }}
//                 >
//                     {festival.description}
//                 </SoftTypography>
//             </SoftBox>
//         </Card>
//     );
// };

// FestivalCard.propTypes = {
//     festival: PropTypes.shape({
//         id: PropTypes.number.isRequired,
//         festival_name: PropTypes.string.isRequired,
//         festival_date: PropTypes.string.isRequired,
//         description: PropTypes.string.isRequired,
//         image_url: PropTypes.string.isRequired,
//         org: PropTypes.string.isRequired,
//     }).isRequired,
// };

// function FestivalList() {
//     const [festivals, setFestivals] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [tabValue, setTabValue] = useState(0);
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchFestivals();
//     }, []);

//     const fetchFestivals = async () => {
//         try {
//             const url = `${APIData.api}festivals/all-Festival?org=${org}`;
//             const response = await axios.get(url, { headers: APIData.headers });
//             setFestivals(response.data && Array.isArray(response.data) ? response.data : []);
//         } catch (error) {
//             Swal.fire({
//                 icon: "error",
//                 title: "Oops...",
//                 text: "Unable to fetch festivals",
//             });
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleTabChange = (event, newValue) => {
//         setTabValue(newValue);
//     };

//     const getFilteredFestivals = () => {
//         const now = new Date();
//         switch (tabValue) {
//             case 1:
//                 return festivals.filter(f => new Date(f.festival_date) > now);
//             case 2:
//                 return festivals.filter(f => new Date(f.festival_date) < now);
//             default:
//                 return festivals;
//         }
//     };

//     const handleNewFestivalClick = () => {
//         navigate("/add-new-festival");
//     };

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <SoftBox p={3}>
//                 <SoftBox
//                     mb={3}
//                     style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "center"
//                     }}
//                 >
//                     <SoftTypography
//                         variant="h4"
//                         fontWeight="medium"
//                         textAlign="left"
//                         textTransform="capitalize"
//                         style={{ margin: 0 }}
//                     >
//                         Festival Calendar
//                     </SoftTypography>

//                     <Tooltip title="Add New Festival" arrow>
//                         <SoftButton
//                             onClick={handleNewFestivalClick}
//                             sx={{
//                                 ml: 2,
//                                 transition: "transform 0.2s ease",
//                                 "&:hover": {
//                                     transform: "scale(1.05)"
//                                 }
//                             }}
//                         >
//                             Add Festival
//                         </SoftButton>
//                     </Tooltip>
//                 </SoftBox>

//                 <Card sx={{ mb: 3 }}>
//                     <AppBar position="static" color="transparent">
//                         <Tabs
//                             value={tabValue}
//                             onChange={handleTabChange}
//                             sx={{
//                                 background: "transparent",
//                                 "& .MuiTab-root": {
//                                     color: "text.primary",
//                                     transition: "all 0.3s ease",
//                                     //   "&:hover": {
//                                     //     color: "primary.main",
//                                     //     transform: "translateY(-2px)"
//                                     //   }
//                                 },
//                                 "& .Mui-selected": {
//                                     color: "primary.main"
//                                 }
//                             }}
//                         >
//                             <Tab
//                                 label="All Festivals"
//                                 sx={{
//                                     color: tabValue === 0 ? "info.main" : "text.secondary",
//                                     fontWeight: tabValue === 0 ? "bold" : "normal",
//                                     borderBottom: tabValue === 0 ? "4px solid" : "none",
//                                     borderColor: "info.main",
//                                 }} />
//                             <Tab
//                                 label="Upcoming Festivals"
//                                 sx={{
//                                     color: tabValue === 1 ? "info.main" : "text.secondary",
//                                     fontWeight: tabValue === 1 ? "bold" : "normal",
//                                     borderBottom: tabValue === 1 ? "4px solid" : "none",
//                                     borderColor: "info.main",
//                                 }} />
//                             <Tab
//                                 label="Past Festivals"
//                                 sx={{
//                                     color: tabValue === 2 ? "info.main" : "text.secondary",
//                                     fontWeight: tabValue === 2 ? "bold" : "normal",
//                                     borderBottom: tabValue === 2 ? "4px solid" : "none",
//                                     borderColor: "info.main",
//                                 }} />
//                         </Tabs>
//                     </AppBar>
//                 </Card>

//                 {loading ? (
//                     <SoftBox display="flex" justifyContent="center" alignItems="center" minHeight="200px">
//                         <CircularProgress />
//                     </SoftBox>
//                 ) : getFilteredFestivals().length > 0 ? (
//                     <Grid container spacing={3}>
//                         {getFilteredFestivals().map((festival, index) => (
//                             <Grid
//                                 item
//                                 xs={12}
//                                 sm={6}
//                                 md={4}
//                                 key={festival.id}
//                                 sx={{
//                                     opacity: 0,
//                                     animation: "fadeIn 0.5s ease forwards",
//                                     animationDelay: `${index * 0.1}s`,
//                                     "@keyframes fadeIn": {
//                                         "0%": {
//                                             opacity: 0,
//                                             transform: "translateY(20px)"
//                                         },
//                                         "100%": {
//                                             opacity: 1,
//                                             transform: "translateY(0)"
//                                         }
//                                     }
//                                 }}
//                             >
//                                 <FestivalCard festival={festival} />
//                             </Grid>
//                         ))}
//                     </Grid>
//                 ) : (
//                     <SoftTypography
//                         variant="body2"
//                         textAlign="center"
//                         sx={{
//                             opacity: 0,
//                             animation: "fadeIn 0.5s ease forwards",
//                             "@keyframes fadeIn": {
//                                 "0%": { opacity: 0 },
//                                 "100%": { opacity: 1 }
//                             }
//                         }}
//                     >
//                         No festivals available.
//                     </SoftTypography>
//                 )}
//             </SoftBox>
//         </DashboardLayout>
//     );
// }

// export default FestivalList;


import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Tooltip, CircularProgress } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import axios from "axios";
import Swal from "sweetalert2";
import { APIData, org } from 'authentication/APIData';
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SoftInput from "components/SoftInput";

const EditFestivalDialog = ({ open, onClose, festival, onUpdate }) => {
    const [formData, setFormData] = useState({
        fest_name: '',
        fest_date: '',
        des: '',
        image: null,
        existing_image_url: ''
    });
    const [imagePreview, setImagePreview] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (festival) {
            setFormData({
                fest_name: festival.festival_name || '',
                fest_date: festival.festival_date ? festival.festival_date.split('T')[0] : '',
                des: festival.description || '',
                image: null,
                existing_image_url: festival.image_url || ''
            });
            setImagePreview(festival.image_url || '');
        }
    }, [festival]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData(prev => ({
                ...prev,
                image: file,
                existing_image_url: ''
            }));
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        const url = `${APIData.api}festivals/updateFestivalImageDetails?id=${festival.id}`;
        const submitData = new FormData();
        submitData.append('id', festival.id);
        submitData.append('fest_name', formData.fest_name);
        submitData.append('fest_date', formData.fest_date);
        submitData.append('des', formData.des);
        submitData.append('org', org);

        if (formData.image) {
            submitData.append('image', formData.image);
        } else if (formData.existing_image_url) {
            submitData.append('image', formData.existing_image_url);
        }

        submitData.forEach((value, key) => {
            console.log(`${key}: ${value}`);
        });
    

        try {
            const response = await axios.put(url, submitData, {
                headers: { 
                    ...APIData.headers, 
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            console.log(response.status);
            
            if (response.status === 201 || response.status === 200) {
                onClose();
                Swal.fire({
                    icon: 'success',
                    title: 'Updated successfully'
                });
                window.location.reload()
                
            }
        } catch (error) {
            onClose();
            Swal.fire({
                icon: 'error',
                title: 'Update failed',
                text: error.message
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            maxWidth="sm" 
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '10px',
                }
            }}
        >
            <DialogTitle sx={{ 
                pb: 2,
                borderBottom: '1px solid',
                borderColor: 'divider'
            }}>
                Edit Festival Details
            </DialogTitle>
            <DialogContent>
                <SoftBox sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    <SoftBox mb={2}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Festival Name
                        </SoftTypography>
                        <SoftInput
                            fullWidth
                            name="fest_name"
                            value={formData.fest_name}
                            onChange={handleChange}
                            placeholder="Enter festival name"
                        />
                    </SoftBox>

                    <SoftBox mb={2}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Festival Date
                        </SoftTypography>
                        <SoftInput
                            fullWidth
                            type="date"
                            name="fest_date"
                            value={formData.fest_date}
                            onChange={handleChange}
                        />
                    </SoftBox>

                    <SoftBox mb={2}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Description
                        </SoftTypography>
                        <SoftInput
                            fullWidth
                            multiline
                            rows={4}
                            name="des"
                            value={formData.des}
                            onChange={handleChange}
                            placeholder="Enter festival description"
                        />
                    </SoftBox>

                    <SoftBox mb={2}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Festival Image
                        </SoftTypography>
                        <SoftBox
                            sx={{
                                mt: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2
                            }}
                        >
                            {imagePreview && (
                                <SoftBox
                                    component="img"
                                    src={imagePreview}
                                    alt="Festival preview"
                                    sx={{
                                        width: '100%',
                                        maxHeight: '200px',
                                        objectFit: 'cover',
                                        borderRadius: '8px'
                                    }}
                                    onError={(e) => {
                                        e.target.src = "/api/placeholder/400/320";
                                    }}
                                />
                            )}
                            <SoftButton
                                component="label"
                                variant="outlined"
                                color="info"
                                sx={{ mt: 1 }}
                            >
                                Choose New Image
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </DialogContent>
            <DialogActions sx={{ p: 2.5, borderTop: '1px solid', borderColor: 'divider' }}>
                <SoftButton 
                    onClick={onClose} 
                    color="secondary"
                    variant="outlined"
                    sx={{ mr: 1 }}
                >
                    Cancel
                </SoftButton>
                <SoftButton 
                    onClick={handleSubmit} 
                    color="info" 
                    variant="contained"
                    disabled={loading}
                >
                    {loading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        'Save Changes'
                    )}
                </SoftButton>
            </DialogActions>
        </Dialog>
    );
};

EditFestivalDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    festival: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
};

const FestivalCard = ({ festival, onFestivalUpdate }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [userdata, setUserData] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
        setUserData(userData_Local);
    }, []);

    const getButtonVisibility = () => {
        if (!userdata) {
            return { showEditButton: false, showDeleteButton: false };
        }
        const userType = userdata.userType?.toLowerCase();
        return {
            showEditButton: userType === 'superadmin' || userType === 'employee',
            showDeleteButton: userType === 'superadmin'
        };
    };

    const { showEditButton, showDeleteButton } = getButtonVisibility();

    const handleEdit = () => {
        setEditDialogOpen(true);
    };

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const url = `${APIData.api}festivals/?id=${festival.id}`;
                axios.delete(url, { headers: APIData.headers })
                    .then((response) => {
                        if (response.status === 200) {
                            Swal.fire(
                                'Deleted!',
                                'Festival has been deleted.',
                                'success'
                            );
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Delete failed',
                            text: error.message
                        });
                    });
            }
        });
    };

    const cardStyle = {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        transform: isHovered ? "translateY(-8px)" : "none",
        boxShadow: isHovered
            ? "0 10px 20px rgba(0,0,0,0.2)"
            : "0 2px 4px rgba(0,0,0,0.1)",
    };

    const imageContainerStyle = {
        position: "relative",
        paddingTop: "56.25%",
        overflow: "hidden",
    };

    const imageStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        transition: "transform 0.3s ease-in-out",
        transform: isHovered ? "scale(1.05)" : "scale(1)",
    };

    const overlayStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "linear-gradient(to top, rgba(0,0,0,0.6) 0%, transparent 100%)",
    };

    const actionButtonsStyle = {
        position: "absolute",
        top: 8,
        right: 8,
        display: "flex",
        gap: "8px",
        opacity: isHovered ? 1 : 0,
        transition: "opacity 0.3s ease-in-out",
    };

    return (
        <>
            <Card
                sx={cardStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div style={imageContainerStyle}>
                    <img
                        src={festival.image_url}
                        alt={festival.festival_name}
                        style={imageStyle}
                        onError={(e) => {
                            e.target.src = "/api/placeholder/400/320";
                        }}
                    />
                    <div style={overlayStyle} />

                    <div style={actionButtonsStyle}>
                        {showEditButton && (
                            <Tooltip title="Edit Festival">
                                <SoftButton
                                    size="small"
                                    color="info"
                                    variant="contained"
                                    onClick={handleEdit}
                                    sx={{
                                        minWidth: '32px',
                                        height: '32px',
                                        p: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                        }
                                    }}
                                >
                                    <EditIcon sx={{ fontSize: 18 }} />
                                </SoftButton>
                            </Tooltip>
                        )}

                        {showDeleteButton && (
                            <Tooltip title="Delete Festival">
                                <SoftButton
                                    size="small"
                                    color="error"
                                    variant="contained"
                                    onClick={handleDelete}
                                    sx={{
                                        minWidth: '32px',
                                        height: '32px',
                                        p: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                        }
                                    }}
                                >
                                    <DeleteIcon sx={{ fontSize: 18 }} />
                                </SoftButton>
                            </Tooltip>
                        )}
                    </div>
                </div>

                <SoftBox p={2} flex={1} sx={{ position: "relative" }}>
                    <SoftTypography
                        variant="h5"
                        fontWeight="bold"
                        textTransform="capitalize"
                        mb={1}
                        sx={{
                            transition: "color 0.3s ease",
                            color: isHovered ? "info.main" : "text.primary"
                        }}
                    >
                        {festival.festival_name}
                    </SoftTypography>

                    <SoftTypography
                        variant="body2"
                        color="text"
                        mb={2}
                    >
                        {new Date(festival.festival_date).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })}
                    </SoftTypography>

                    <SoftTypography
                        variant="body2"
                        color="text"
                        sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {festival.description}
                    </SoftTypography>
                </SoftBox>
            </Card>

            <EditFestivalDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                festival={festival}
                onUpdate={onFestivalUpdate}
            />
        </>
    );
};

FestivalCard.propTypes = {
    festival: PropTypes.shape({
        id: PropTypes.number.isRequired,
        festival_name: PropTypes.string.isRequired,
        festival_date: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image_url: PropTypes.string.isRequired,
        org: PropTypes.string.isRequired,
    }).isRequired,
    onFestivalUpdate: PropTypes.func.isRequired,
};

function FestivalList() {
    const [festivals, setFestivals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        fetchFestivals();
    }, []);

    const fetchFestivals = async () => {
        try {
            const url = `${APIData.api}festivals/all-Festival?org=${org}`;
            const response = await axios.get(url, { headers: APIData.headers });
            setFestivals(response.data && Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Unable to fetch festivals",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getFilteredFestivals = () => {
        const now = new Date();
        switch (tabValue) {
            case 1:
                return festivals.filter(f => new Date(f.festival_date) > now);
            case 2:
                return festivals.filter(f => new Date(f.festival_date) < now);
            default:
                return festivals;
        }
    };

    const handleNewFestivalClick = () => {
        navigate("/add-new-festival");
    };

    const handleFestivalUpdate = () => {
        fetchFestivals();
    };

        return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox p={3}>
                <SoftBox
                    mb={3}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <SoftTypography
                        variant="h4"
                        fontWeight="medium"
                        textAlign="left"
                        textTransform="capitalize"
                        style={{ margin: 0 }}
                    >
                        Festival Calendar
                    </SoftTypography>

                    <Tooltip title="Add New Festival" arrow>
                        <SoftButton
                            onClick={handleNewFestivalClick}
                            sx={{
                                ml: 2,
                                transition: "transform 0.2s ease",
                                "&:hover": {
                                    transform: "scale(1.05)"
                                }
                            }}
                        >
                            Add Festival
                        </SoftButton>
                    </Tooltip>
                </SoftBox>

                <Card sx={{ mb: 3 }}>
                    <AppBar position="static" color="transparent">
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            sx={{
                                background: "transparent",
                                "& .MuiTab-root": {
                                    color: "text.primary",
                                    transition: "all 0.3s ease",
                                },
                                "& .Mui-selected": {
                                    color: "primary.main"
                                }
                            }}
                        >
                            <Tab
                                label="All Festivals"
                                sx={{
                                    color: tabValue === 0 ? "info.main" : "text.secondary",
                                    fontWeight: tabValue === 0 ? "bold" : "normal",
                                    borderBottom: tabValue === 0 ? "4px solid" : "none",
                                    borderColor: "info.main",
                                }} />
                            <Tab
                                label="Upcoming Festivals"
                                sx={{
                                    color: tabValue === 1 ? "info.main" : "text.secondary",
                                    fontWeight: tabValue === 1 ? "bold" : "normal",
                                    borderBottom: tabValue === 1 ? "4px solid" : "none",
                                    borderColor: "info.main",
                                }} />
                            <Tab
                                label="Past Festivals"
                                sx={{
                                    color: tabValue === 2 ? "info.main" : "text.secondary",
                                    fontWeight: tabValue === 2 ? "bold" : "normal",
                                    borderBottom: tabValue === 2 ? "4px solid" : "none",
                                    borderColor: "info.main",
                                }} />
                        </Tabs>
                    </AppBar>
                </Card>

                {loading ? (
                    <SoftBox display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                        <CircularProgress />
                    </SoftBox>
                ) : getFilteredFestivals().length > 0 ? (
                    <Grid container spacing={3}>
                        {getFilteredFestivals().map((festival, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={festival.id}
                                sx={{
                                    opacity: 0,
                                    animation: "fadeIn 0.5s ease forwards",
                                    animationDelay: `${index * 0.1}s`,
                                    "@keyframes fadeIn": {
                                        "0%": {
                                            opacity: 0,
                                            transform: "translateY(20px)"
                                        },
                                        "100%": {
                                            opacity: 1,
                                            transform: "translateY(0)"
                                        }
                                    }
                                }}
                            >
                                <FestivalCard festival={festival} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <SoftTypography
                        variant="body2"
                        textAlign="center"
                        sx={{
                            opacity: 0,
                            animation: "fadeIn 0.5s ease forwards",
                            "@keyframes fadeIn": {
                                "0%": { opacity: 0 },
                                "100%": { opacity: 1 }
                            }
                        }}
                    >
                        No festivals available.
                    </SoftTypography>
                )}
            </SoftBox>
        </DashboardLayout>
    );
}

export default FestivalList;