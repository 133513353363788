import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData } from 'authentication/APIData';
import { Link, useNavigate } from 'react-router-dom';
import SoftButton from 'components/SoftButton';
import PropTypes from 'prop-types';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import { Tooltip } from '@mui/material';

const Name = ({ name, email }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                alt={name}
                size="sm"
                variant="rounded"
            />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
                {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
                {email}
            </SoftTypography>
        </SoftBox>
    </SoftBox>
);

Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

const ViewTickets = () => {
    const [ticketData, setTicketData] = useState([]);
    const [userdata, setUserData] = useState();
    const navigate=useNavigate();

    useEffect(() => {
        const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
        setUserData(userData_Local);
    }, []);

    useEffect(() => {
        if (userdata && userdata.customerId) {
            const customerId = userdata.customerId.toLowerCase();
            const fetchTicketData = async () => {
                const url = `${APIData.api}Ticket/consumerId?consumerId=${customerId}`;

                try {
                    const response = await fetch(url, { headers: APIData.headers });
                    const data = await response.json();
                    setTicketData(data);
                } catch (error) {
                    console.error('Error fetching ticket data:', error);
                }
            };

            fetchTicketData();
        }
    }, [userdata]);

    const columns = [
        { name: "Name", align: "left" },
        { name: "Ticket ID", align: "left" },
        { name: "Type", align: "left" },
        { name: "Product", align: "left" },
        { name: "Status", align: "left" },
        { name: "Contact", align: "left" },
        { name: "Priority", align: "left" },
        { name: "SLA", align: "left" },
        { name: "Action", align: "center" },
    ];

    const rows = ticketData.map((ticket) => ({
        Name: <Name name={ticket.name} email={ticket.emailid} />,
        Product: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {ticket.product}
            </SoftTypography>
        ),
        Type: (
            <SoftBadge
                variant="gradient"
                badgeContent={ticket.type.toLowerCase()}
                color="light"
                size="xs"
                container
            />
        ),
        "Ticket ID": (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
                {ticket.ticket_id}
            </SoftTypography>
        ),
        Status: (
            <SoftBadge
                variant="gradient"
                badgeContent={ticket.status.toLowerCase()}
                color={
                    ticket.status === "REQUESTED" ? "info" :
                    ticket.status === "PENDING" ? "warning" :
                    "success"
                }
                size="xs"
                container
            />
        ),
        Priority: (
            <SoftBadge
                variant="gradient"
                badgeContent={ticket.priority.toLowerCase()}
                color={
                    ticket.priority === "CRITICAL" ? "error" :
                    ticket.priority === "HIGH" ? "warning" :
                    "secondary"
                }
                size="xs"
                container
            />
        ),
        Contact: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {ticket.phone_number}
            </SoftTypography>
        ),
        SLA: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {ticket.sla}
            </SoftTypography>
        ),
        Action: (
            <Link to={`/view-ticket-status/${ticket.id}`}>
                <SoftTypography
                    component="span"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                >
                    View
                </SoftTypography>
            </Link>
        ),
    }));

    const handleNewQueryClick = () => {
        navigate("/ticket-form");
      };

    return (
        <PageLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h6">Ticket List</SoftTypography>
                        <Tooltip title="Raise a New Query/Ticket" arrow>
                        <SoftButton sx={{ ml: 2 }} onClick={handleNewQueryClick}>+ Add</SoftButton>
                        </Tooltip>
                    </SoftBox>
                    <Card>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </PageLayout>
    );
};

export default ViewTickets;
