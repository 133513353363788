import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIData, org } from '../authentication/APIData';
import {
    Grid,
    Card,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const CreateMarks = () => {
    const [loading, setLoading] = useState(false);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [data, setData] = useState([]);
    const [enrolledData, setEnrolledData] = useState([]);
    const [errors, setErrors] = useState({});
    const [touchedFields, setTouchedFields] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);


    const requiredFields = [
        'email_id',
        'student_id',
        'name',
        'grade',
        'max_score',
        'first_language',
        'second_language',
        'third_language',
        'mathematics',
        'science',
        'social',
        'months',
        'test_date',
        'test_type'
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${APIData.api}courses-enrolled?org=${org}`;
                const response = await axios.get(url, { headers: APIData.headers });
                console.log("Response data: ", response.data);
                setData(response.data);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        const fetchEnrolledData = async () => {
            try {
                const url = `${APIData.api}enrollments?org=${org}`;
                const response = await axios.get(url, { headers: APIData.headers });
                console.log("Response enrolled data: ", response.data);
                setEnrolledData(response.data);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
        fetchEnrolledData();
    }, []);

    const [formData, setFormData] = useState({
        email_id: "",
        first_language: "",
        grade: "",
        id: 0,
        mathematics: "",
        max_score: "",
        months: "",
        name: "",
        org: org,
        percentage: "",
        science: "",
        second_language: "",
        social: "",
        student_id: "",
        test_date: "",
        test_type: "",
        third_language: "",
        total: ""
    });

    const validateField = (name, value) => {
        let error = "";

        // Required field validation
        if (requiredFields.includes(name) && !value) {
            error = `${name.replace(/_/g, ' ').charAt(0).toUpperCase() + name.slice(1)} is required`;
        }

        // Additional validation for numeric fields
        if (['first_language', 'second_language', 'third_language', 'mathematics', 'science', 'social'].includes(name)) {
            if (value && (isNaN(value) || parseFloat(value) < 0)) {
                error = "Please enter a valid positive number";
            }
            if (value && parseFloat(value) > parseFloat(formData.max_score)) {
                error = `Cannot exceed maximum score of ${formData.max_score}`;
            }
        }

        if (name === 'max_score' && value && (isNaN(value) || parseFloat(value) <= 0)) {
            error = "Maximum score must be a positive number";
        }

        return error;
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        // Validate all required fields
        requiredFields.forEach(field => {
            const error = validateField(field, formData[field]);
            if (error) {
                newErrors[field] = error;
                isValid = false;
            }
        });

        setErrors(newErrors);
        setIsFormValid(isValid);
        return isValid;
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                [name]: value
            };

            // Update related fields if student_id changes
            if (name === 'student_id') {
                const selectedStudent = data.find(student => student.student_id === value);
                if (selectedStudent) {
                    updatedFormData.grade = selectedStudent.course_id;
                }
            }

            // Calculate total and percentage
            const total = ['first_language', 'second_language', 'third_language', 'mathematics', 'science', 'social']
                .reduce((sum, key) => sum + (parseFloat(updatedFormData[key]) || 0), 0);

            updatedFormData.total = total;

            if (parseFloat(updatedFormData.max_score) > 0) {
                updatedFormData.percentage = ((total / parseFloat(updatedFormData.max_score)) * 100).toFixed(2);
            }

            return updatedFormData;
        });

        setTouchedFields(prev => ({
            ...prev,
            [name]: true
        }));

        const error = validateField(name, value);
        setErrors(prev => ({
            ...prev,
            [name]: error
        }));

        // Validate entire form to update submit button state
        setTimeout(validateForm, 0);
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : '';

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        setTouchedFields(prev => ({
            ...prev,
            [name]: true
        }));

        const error = validateField(name, value);
        setErrors(prev => ({
            ...prev,
            [name]: error
        }));

        // Validate entire form to update submit button state
        setTimeout(validateForm, 0);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Mark all fields as touched
        const allTouched = requiredFields.reduce((acc, field) => ({
            ...acc,
            [field]: true
        }), {});
        setTouchedFields(allTouched);

        if (!validateForm()) {
            Swal.fire({
                icon: "error",
                title: "Please fill all required fields",
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        setLoading(true);
        try {
            const url = APIData.api + `marks/`;
            const response = await axios.post(url, formData, { headers: APIData.headers });
            console.log(response.data);
            Swal.fire({
                icon: "success",
                title: "Marks added successfully",
                showConfirmButton: false,
                timer: 1500
            });
            setTimeout(() => {
                window.history.back()
            }, 1500);
            // Reset form
            setFormData({
                email_id: "",
                first_language: "",
                grade: "",
                id: 0,
                mathematics: "",
                max_score: "",
                months: "",
                name: "",
                org: org,
                percentage: "",
                science: "",
                second_language: "",
                social: "",
                student_id: "",
                test_date: "",
                test_type: "",
                third_language: "",
                total: ""
            });
            setTouchedFields({});
            setErrors({});
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Error adding marks",
                text: error.response?.data?.errorDesc || "An error occurred",
            });
        } finally {
            setLoading(false);
        }
    };

    const emailOptions = enrolledData
        .filter(enrollment => enrollment.enrollment_type === "STUDENT")
        .map(enrollment => ({
            value: enrollment.user_email,
            label: enrollment.user_email
        }));

    const studentIdOptions = data.map(student => ({
        value: student.student_id,
        label: student.student_id
    }));

    const monthOptions = months.map(month => ({
        value: month,
        label: month
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4">
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1}>
                            <SoftTypography variant="h5" fontWeight="medium" textAlign="center">
                                Add Marks
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox pt={2} pb={3} px={3}>
                            <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Email ID</SoftTypography>
                                            <CustomSelect
                                                options={emailOptions}
                                                value={formData.email_id}
                                                placeholder="Select Email"
                                                onChange={handleSelectChange}
                                                name="email_id"
                                                error={touchedFields.email_id && !!errors.email_id}
                                            />
                                            {touchedFields.email_id && errors.email_id && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.email_id}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Student ID</SoftTypography>
                                            <CustomSelect
                                                options={studentIdOptions}
                                                value={formData.student_id}
                                                placeholder="Select Student ID"
                                                name="student_id"
                                                onChange={handleSelectChange}
                                                error={touchedFields.student_id && !!errors.student_id}
                                            />
                                            {touchedFields.student_id && errors.student_id && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.student_id}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Test Name</SoftTypography>
                                            <SoftInput
                                                placeholder="Test Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                error={touchedFields.name && !!errors.name}
                                            />
                                            {touchedFields.name && errors.name && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.name}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Grade</SoftTypography>
                                            <SoftInput
                                                placeholder="Grade"
                                                name="grade"
                                                value={formData.grade}
                                                onChange={handleChange}
                                                error={touchedFields.grade && !!errors.grade}
                                            />
                                            {touchedFields.grade && errors.grade && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.grade}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Max Score</SoftTypography>
                                            <SoftInput
                                                type="number"
                                                placeholder="Max Score"
                                                name="max_score"
                                                value={formData.max_score}
                                                onChange={handleChange}
                                                error={touchedFields.max_score && !!errors.max_score}
                                            />
                                            {touchedFields.max_score && errors.max_score && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.max_score}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>First Language</SoftTypography>
                                            <SoftInput
                                                type="number"
                                                placeholder="First Language"
                                                name="first_language"
                                                value={formData.first_language}
                                                onChange={handleChange}
                                                error={touchedFields.first_language && !!errors.first_language}
                                            />
                                            {touchedFields.first_language && errors.first_language && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.first_language}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Second Language</SoftTypography>
                                            <SoftInput
                                                type="number"
                                                placeholder="Second Language"
                                                name="second_language"
                                                value={formData.second_language}
                                                onChange={handleChange}
                                                error={touchedFields.second_language && !!errors.second_language}
                                            />
                                            {touchedFields.second_language && errors.second_language && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.second_language}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Third Language</SoftTypography>
                                            <SoftInput
                                                type="number"
                                                placeholder="Third Language"
                                                name="third_language"
                                                value={formData.third_language}
                                                onChange={handleChange}
                                                error={touchedFields.third_language && !!errors.third_language}
                                            />
                                            {touchedFields.third_language && errors.third_language && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.third_language}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Mathematics</SoftTypography>
                                            <SoftInput
                                                type="number"
                                                placeholder="Mathematics"
                                                name="mathematics"
                                                value={formData.mathematics}
                                                onChange={handleChange}
                                                error={touchedFields.mathematics && !!errors.mathematics}
                                            />
                                            {touchedFields.mathematics && errors.mathematics && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.mathematics}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Science</SoftTypography>
                                            <SoftInput
                                                type="number"
                                                placeholder="Science"
                                                name="science"
                                                value={formData.science}
                                                onChange={handleChange}
                                                error={touchedFields.science && !!errors.science}
                                            />
                                            {
                                                touchedFields.science && errors.science && (
                                                    <SoftTypography variant="caption" color="error">
                                                        {errors.science}
                                                    </SoftTypography>
                                                )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Social</SoftTypography>
                                            <SoftInput
                                                type="number"
                                                placeholder="Social"
                                                name="social"
                                                value={formData.social}
                                                onChange={handleChange}
                                                error={touchedFields.social && !!errors.social}
                                            />
                                            {touchedFields.social && errors.social && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.social}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Total</SoftTypography>
                                            <SoftInput
                                                type="number"
                                                placeholder="Total"
                                                name="total"
                                                value={formData.total}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Percentage</SoftTypography>
                                            <SoftInput
                                                placeholder="Percentage"
                                                name="percentage"
                                                value={formData.percentage}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Month</SoftTypography>
                                            <CustomSelect
                                                options={monthOptions}
                                                value={formData.months}
                                                placeholder="Select Month"
                                                name="months"
                                                onChange={handleSelectChange}
                                                error={touchedFields.months && !!errors.months}
                                                
                                            />
                                            {touchedFields.months && errors.months && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.months}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Test Date</SoftTypography>
                                            <SoftInput
                                                type="date"
                                                placeholder="Test Date"
                                                name="test_date"
                                                value={formData.test_date}
                                                onChange={handleChange}
                                                error={touchedFields.test_date && !!errors.test_date}
                                            />
                                            {touchedFields.test_date && errors.test_date && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.test_date}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Test Type</SoftTypography>
                                            <SoftInput
                                                placeholder="Test Type"
                                                name="test_type"
                                                value={formData.test_type}
                                                onChange={handleChange}
                                                error={touchedFields.test_type && !!errors.test_type}
                                            />
                                            {touchedFields.test_type && errors.test_type && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.test_type}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox mt={4} mb={1}>
                                            <SoftButton
                                                variant="gradient"
                                                color="info"
                                                fullWidth
                                                onClick={handleSubmit}
                                                disabled={loading}
                                            >
                                                {loading ? "Submitting..." : "Submit Marks"}
                                            </SoftButton>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </DashboardLayout>
    );
};

export default CreateMarks;