// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftAvatar from "components/SoftAvatar";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
// import { APIData, org } from '../authentication/APIData';
// import SoftButton from 'components/SoftButton';
// import { Link } from 'react-router-dom';
// import Swal from 'sweetalert2';

// const Name = ({ name, email }) => {
//     const [showFullName, setShowFullName] = useState(false);

//     const truncationLength = 30;

//     const displayedName = showFullName || name.length <= truncationLength
//         ? name
//         : `${name.substring(0, truncationLength)}...`;

//     const handleToggleFullName = () => {
//         setShowFullName(!showFullName);
//     };

//     return (
//         <SoftBox display="flex" alignItems="center"  py={0.5}>
//             <SoftBox mr={2}>
//                 <SoftAvatar
//                     src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
//                     alt={name}
//                     size="sm"
//                     variant="rounded"
//                 />
//             </SoftBox>
//             <SoftBox display="flex" flexDirection="column">
//                 <SoftTypography variant="button" fontWeight="medium">
//                     {displayedName}
//                 </SoftTypography>
//                 {name.length > truncationLength && (
//                     <SoftTypography
//                         variant="caption"
//                         color="info"
//                         onClick={handleToggleFullName}
//                         style={{ cursor: "pointer" }}
//                     >
//                         {showFullName ? "Show Less" : "Read More"}
//                     </SoftTypography>
//                 )}
//                 <SoftTypography variant="caption" color="secondary">
//                     {email}
//                 </SoftTypography>
//             </SoftBox>
//         </SoftBox>
//     );
// };

// Name.propTypes = {
//     name: PropTypes.string.isRequired,
//     email: PropTypes.string.isRequired,
// };



// const Description = ({ description }) => {
//     const [showFullDescription, setShowFullDescription] = useState(false);


//     const isLongDescription = description.length > 20;

//     const displayedDescription = showFullDescription || !isLongDescription
//         ? description
//         : `${description.substring(0, 20)}...`;

//     const handleToggleDescription = () => {
//         setShowFullDescription(!showFullDescription);
//     };

//     return (
//         <SoftBox display="flex" flexDirection="column">
//             <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//                 {displayedDescription}
//             </SoftTypography>
//             {isLongDescription && (
//                 <SoftTypography
//                     variant="caption"
//                     color="info"
//                     onClick={handleToggleDescription}
//                     style={{ cursor: "pointer" }}
//                 >
//                     {showFullDescription ? "Show Less" : "Read More"}
//                 </SoftTypography>
//             )}
//         </SoftBox>
//     );
// };

// Description.propTypes = {
//     description: PropTypes.string.isRequired,
// };
// const PasswordReq = () => {
//     const [passwordData, setPasswordData] = useState([]);

//     const fetchData = async () => {
//         const baseUrl = `${APIData.api}passwordaccess/details?org=${org}`;

//         try {
//             const response = await fetch(baseUrl, { headers: APIData.headers });
//             const data = await response.json();
//             setPasswordData(data);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     useEffect(() => {
//         fetchData();
//     }, []);

//     const showErrorAlert = (message) => {
//         Swal.fire({
//             icon: 'error',
//             title: 'Error',
//             text: message,
//             confirmButtonColor: '#3085d6'
//         });
//     };

//     const showSuccessAlert = (message) => {
//         Swal.fire({
//             icon: 'success',
//             title: 'Success',
//             text: message,
//             confirmButtonColor: '#3085d6'
//         });
//     };


//     const handleDelete = async (title) => {
//         const result = await Swal.fire({
//             title: 'Are you sure?',
//             text: `Do you want to delete the password for "${title}"?`,
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#3085d6',
//             cancelButtonColor: '#d33',
//             confirmButtonText: 'Yes, delete it!',
//             cancelButtonText: 'Cancel'
//         });

//         if (result.isConfirmed) {
//             try {
//                 Swal.fire({
//                     title: 'Deleting...',
//                     allowOutsideClick: false,
//                     didOpen: () => {
//                         Swal.showLoading();
//                     }
//                 });
//                 const response = await fetch(
//                     `${APIData.api}orgpasswords/delete?title=${title}`,
//                     {
//                         method: 'DELETE',
//                         headers: { ...APIData.headers, 'Content-Type': 'application/json' },
//                     }
//                 );
//                 if (response.ok) {
//                     setPasswordData(prevData =>
//                         prevData.filter(item => item.title !== title)
//                     );
//                     showSuccessAlert('Password deleted successfully');
//                 } else {
//                     const errorData = await response.json();
//                     showErrorAlert(errorData.message || 'Failed to delete password');
//                 }
//             } catch (error) {
//                 console.error('Error deleting password:', error);
//                 showErrorAlert('Failed to delete password. Please try again.');
//             }
//         }
//     };

//     const columns = [
//         { name: "Title", align: "left" },
//         { name: "Requested_by", align: "left" },
//         { name: "Reason", align: "left" },
//         { name: "Status", align: "left" },
//         { name: "Action", align: "center" },
//     ];

//     const rows = passwordData.map((pass) => ({
//         Title: <Name name={pass.title} email={pass.created_by} />,
//         Requested_by: <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//             {pass.requested_by}
//         </SoftTypography>,
//         Reason: <Description description={pass.reason} />,
//         Status: <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//             {pass.status}
//         </SoftTypography>,
//         Action: (
//             <>
//                 <Link >
//                     <SoftTypography
//                         component="a"
//                         href="#"
//                         variant="button"
//                         color="secondary"
//                         fontWeight="medium"
//                     >
//                         Edit
//                     </SoftTypography>
//                 </Link>
//                 {/* <SoftTypography
//                     component="a"
//                     href="#"
//                     variant="button"
//                     color="error"
//                     fontWeight="medium"
//                     sx={{ marginLeft: "10px", cursor: "pointer" }}
//                     onClick={() => handleDelete(pass.title)}
//                 >
//                     Delete
//                 </SoftTypography> */}

//             </>
//         ),
//     }));

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <SoftBox py={3}>
//                 <SoftBox mb={3}>
//                     <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
//                         <SoftTypography variant="h6">Passwords Request</SoftTypography>
//                     </SoftBox>
//                     <SoftBox
//                         sx={{
//                             "& .MuiTableRow-root:not(:last-child)": {
//                                 "& td": {
//                                     borderBottom: ({ borders: { borderWidth, borderColor } }) =>
//                                         `${borderWidth[1]} solid ${borderColor}`,
//                                 },
//                             },
//                         }}
//                     >
//                         <Table columns={columns} rows={rows} />
//                     </SoftBox>
//                 </SoftBox>
//             </SoftBox>
//             <Footer />
//         </DashboardLayout>
//     );
// };

// export default PasswordReq;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData, org } from '../authentication/APIData';
import SoftButton from 'components/SoftButton';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const Name = ({ name, email }) => {
    const [showFullName, setShowFullName] = useState(false);

    const truncationLength = 30;

    const displayedName = showFullName || name.length <= truncationLength
        ? name
        : `${name.substring(0, truncationLength)}...`;

    const handleToggleFullName = () => {
        setShowFullName(!showFullName);
    };

    return (
        <SoftBox display="flex" alignItems="center" py={0.5}>
            <SoftBox mr={2}>
                <SoftAvatar
                    src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                    alt={name}
                    size="sm"
                    variant="rounded"
                />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
                <SoftTypography variant="button" fontWeight="medium">
                    {displayedName}
                </SoftTypography>
                {name.length > truncationLength && (
                    <SoftTypography
                        variant="caption"
                        color="info"
                        onClick={handleToggleFullName}
                        style={{ cursor: "pointer" }}
                    >
                        {showFullName ? "Show Less" : "Read More"}
                    </SoftTypography>
                )}
                <SoftTypography variant="caption" color="secondary">
                    {email}
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
};

Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

const Description = ({ description }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);


    const isLongDescription = description.length > 20;

    const displayedDescription = showFullDescription || !isLongDescription
        ? description
        : `${description.substring(0, 20)}...`;

    const handleToggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    return (
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {displayedDescription}
            </SoftTypography>
            {isLongDescription && (
                <SoftTypography
                    variant="caption"
                    color="info"
                    onClick={handleToggleDescription}
                    style={{ cursor: "pointer" }}
                >
                    {showFullDescription ? "Show Less" : "Read More"}
                </SoftTypography>
            )}
        </SoftBox>
    );
};

Description.propTypes = {
    description: PropTypes.string.isRequired,
};

const PasswordReq = () => {
    const [passwordData, setPasswordData] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(parseInt(accessLevel));
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    const fetchData = async () => {
        const baseUrl = `${APIData.api}passwordaccess/details?org=${org}`;

        try {
            const response = await fetch(baseUrl, { headers: APIData.headers });
            const data = await response.json();
            setPasswordData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const showErrorAlert = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: message,
            confirmButtonColor: '#3085d6'
        });
    };

    const showSuccessAlert = (message) => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: message,
            confirmButtonColor: '#3085d6'
        });
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete the password for "${id}"?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            try {
                Swal.fire({
                    title: 'Deleting...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                const response = await fetch(
                    `${APIData.api}passwordaccess/delete?id=${id}`,
                    {
                        method: 'DELETE',
                        headers: { ...APIData.headers, 'Content-Type': 'application/json' },
                    }
                );
                if (response.ok) {
                    setPasswordData(prevData =>
                        prevData.filter(item => item.id !== id)
                    );
                    showSuccessAlert('Password deleted successfully');
                } else {
                    const errorData = await response.json();
                    showErrorAlert(errorData.message || 'Failed to delete password');
                }
            } catch (error) {
                console.error('Error deleting password:', error);
                showErrorAlert('Failed to delete password. Please try again.');
            }
        }
    };

    const shouldShowActions = () => {
        if (permission === 1100 || permission === 1000) {
            return false;
        }
        return isSuperAdmin || userDepartment === "SALES";
    };

    const columns = [
        { name: "Title", align: "left" },
        { name: "Requested_by", align: "left" },
        { name: "Reason", align: "left" },
        { name: "Status", align: "left" },
        ...(shouldShowActions() ? [{ name: "Action", align: "center" }] : []),
    ];

    const rows = passwordData.map((pass) => ({
        Title: <Name name={pass.title} email={pass.created_by} />,
        Requested_by: <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {pass.requested_by}
        </SoftTypography>,
        Reason: <Description description={pass.reason} />,
        Status: <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {pass.status}
        </SoftTypography>,
        ...(shouldShowActions() ? {
            Action: (
                <>
                    <Link >
                        <SoftTypography
                            component="a"
                            href="#"
                            variant="button"
                            color="secondary"
                            fontWeight="medium"
                        >
                            Edit
                        </SoftTypography>
                    </Link>
                    {permission === 1111 && (<SoftTypography
                        component="a"
                        href="#"
                        variant="button"
                        color="error"
                        fontWeight="medium"
                        sx={{ marginLeft: "10px", cursor: "pointer" }}
                        onClick={() => handleDelete(pass.id)}
                    >
                        Delete
                    </SoftTypography>)}

                </>
            )
        } : {}),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h6">Passwords Request</SoftTypography>

                    </SoftBox>
                    <SoftBox
                        sx={{
                            "& .MuiTableRow-root:not(:last-child)": {
                                "& td": {
                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                        `${borderWidth[1]} solid ${borderColor}`,
                                },
                            },
                        }}
                    >
                        <Table columns={columns} rows={rows} />
                    </SoftBox>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default PasswordReq;