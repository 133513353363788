import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import CandidateOnBoard from "layouts/CandidateOnboarding";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import CustomerSalesOnboard from "sales/CustomerSalesOnboard"; 
import AssetList from "layouts/stores/assets-list";
import { InputBase } from "@mui/material";
import AddJob from "Super_Admin/Addjob";
import Add_designation from "Super_Admin/Add_designation";
import Carousel from "Home/Carousel";
import JobEditButtons from "sales/JobEditButtons";
import { Salesonboard } from "sales/SalesOnboard";
import EmailServices from "layouts/emailServices";
import OrgMdmdata from "sales/OrgMdmdata";
import Theme from "stepperform/Theme";
import OrgHierarchy from "layouts/orgHierarchy/org-hierarchy";
import StationaryItems from "layouts/stationary/stationaryItems";
import CandidateData from "candidate/CandidateData";
import ProcurementList from "layouts/procurement/procurement-list";
import EmployeeIdCard from "layouts/idCards/employeeIdCard";
import LetterHead from "LetterHeadTemp/LetterHead";
import EnrollmentsData from "SqlTables/EnrollmentsData";
import CoursesTable from "SqlTables/CoursesTable";
import AssignTeacher from "SqlTables/AssignTeacher";
import ClassroomTable from "SqlTables/ClassroomTable";
import TimetableComponent from "SqlTables/TimetableComponent";
import OrgPolicies from "layouts/org-policies/orgpolicies";
import { CreateSuperadmin } from "sales/CraeteSuperadmin";
import CoursesDetails from "Courses/CoursesDetails";
import Courses from "Courses/Courses";
import CoursesEnrolled from "Courses/CoursesEnrolled";
import FestivalList from "layouts/festival/festival-list";
import MarksData from "SqlTables/MarksData";
import AddExpenses from "Expenses/AddExpenses";
import ExpensesDetails from "Expenses/ExpensesDeatils";
import TicketTable from "layouts/ticket/ticket-list";
import AddQuestions from "question_paper/Addquestions";
import ViewQuestions from "question_paper/ViewQuestions";
import QuestionPaperByAll from "question_paper/QuestionPaperByAll";
import Schedule from "ScheduleMeeting";
import OrgPassword from "Password/OrgPassword";
import PasswordReq from "Password/PasswordReq";
import AdminDashboard from "ASAT/Components/Admin/AdminDashboard";
import EditProfile from "layouts/profile/editProfile";
import CustomerOnboard from "sales/CustomerOnboard";
import CustSuportData from "sales/CustSuportData";
import SpocData from "layouts/DepartmentSpoc/SpocData";



const routes = [
  { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },
  { type: "title", title: "Expenses", key: "Expenses" },
  // {
  //   type: "collapse",
  //   name: "Add Expenses",
  //   key: "add-expenses",
  //   route: "/add-expenses",
  //   icon: <Shop size="12px" />,
  //   component: <AddExpenses/>,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Expenses",
    key: "expenses",
    route: "/expenses",
    icon: <Shop size="12px" />,
    component: <ExpensesDetails/>,
    noCollapse: true,
  },
  { type: "title", title: "Courses", key: "Courses" },
  {
    type: "collapse",
    name: "Courses",
    key: "Courses",
    route: "/courses-details",
    icon: <Shop size="12px" />,
    component: <CoursesDetails/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Apply for Courses",
    key: "Courses",
    route: "/Apply-courses",
    icon: <Shop size="12px" />,
    component: <Courses/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Enrolled Courses",
    key: "enrolled-Courses",
    route: "/enrolled-Courses",
    icon: <Shop size="12px" />,
    component: <CoursesEnrolled/>,
    noCollapse: true,
  },
  { type: "title", title: "Sales", key: "Sales" },
  {
    type: "collapse",
    name: "Customer Sales Onboarding",
    key: "customerSalesOnboarding",
    route: "/customerSalesOnboard",
    icon: <Shop size="12px" />,
    component: <CustomerSalesOnboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Customer Mdm Onboarding",
    key: "orgmdmdata",
    route: "/orgmdmdata",
    icon: <Shop size="12px" />,
    component: <OrgMdmdata/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Create-superadmin",
    key: "create-superadmin",
    route: "/create-superadmin",
    icon: <Shop size="12px" />,
    component: <CreateSuperadmin />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Customer Onboard",
    key: "customer-support",
    route: "/customer-support",
    icon: <Shop size="12px" />,
    component: <CustSuportData/>,
    noCollapse: true,
  },
  { type: "title", title: "Password", key: "Password" },
  {
    type: "collapse",
    name: "Passwords",
    key: "passwor",
    route: "/password-managing",
    icon: <Shop size="12px" />,
    component: <OrgPassword/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Requested",
    key: "requested",
    route: "/requested-password",
    icon: <Shop size="12px" />,
    component: <PasswordReq/>,
    noCollapse: true,
  },
  { type: "title", title: "Question", key: "Question" },
  {
    type: "collapse",
    name: "Addquestions",
    key: "addquestions",
    route: "/addquestions",
    icon: <CreditCard size="12px" />,
    component: <AddQuestions />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "ViewQuestions",
    key: "viewQuestions",
    route: "/viewQuestions",
    icon: <CreditCard size="12px" />,
    component: <ViewQuestions/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "All Questions",
    key: "all-questions",
    route: "/all-questions",
    icon: <CreditCard size="12px" />,
    component: <QuestionPaperByAll/>,
    noCollapse: true,
  },
  { type: "title", title: "TimeTable", key: "TimeTable" },
  {
    type: "collapse",
    name: "CoursesTable",
    key: "coursesTable",
    route: "/coursesTable",
    icon: <Shop size="12px" />,
    component: <CoursesTable />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "ClassroomTable",
    key: "classroomTable",
    route: "/classroomTable",
    icon: <Shop size="12px" />,
    component: <ClassroomTable />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "TimetableComponent",
    key: "timetableComponent",
    route: "/timetableComponent",
    icon: <Shop size="12px" />,
    component: <TimetableComponent />,
    noCollapse: true,
  },
  { type: "title", title: "Job", key: "Job" },
  {
    type: "collapse",
    name: "Add job",
    key: "addjob",
    route: "/addjob",
    icon: <Shop size="12px" />,
    component: <AddJob />,
    noCollapse: true, 
  },
  {
    type: "collapse",
    name: "Add Designation",
    key: "add_designation",
    route: "/add_designation",
    icon: <Shop size="12px" />,
    component: <Add_designation />,
    noCollapse: true, 
  },

  {
    type: "collapse",
    name: "View Jobs",
    key: "jobEditButtons",
    route: "/jobEditButtons",
    icon: <Shop size="12px" />,
    component: <JobEditButtons />,
    noCollapse: true,
  },

  { type: "title", title: "Quick Links", key: "Quick-Links" },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "ASAT Dashboard",
    key: "adminDashboard",
    route: "/adminDashboard",
    icon: <CreditCard size="12px" />,
    component: <AdminDashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Candidate Onboarding",
    key: "candidateData",
    route: "/candidateData",
    icon: <Shop size="12px" />,
    component: <CandidateData/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Department Spoc",
    key: "depart-spoc",
    route: "/depart-spoc",
    icon: <Shop size="12px" />,
    component: <SpocData/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Scehdule Meeting",
    key: "Schedule meeting",
    route: "/schedule-meeting",
    icon: <Shop size="12px" />,
    component: <Schedule/>,
    noCollapse: true,
  },
 
  {
    type: "collapse",
    name: "Email Service",
    key: "Email Service",
    route: "/emailServices",
    icon: <Shop size="12px" />,
    component: <EmailServices />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: " Stepper",
    key: "stepper",
    route: "/stepper",
    icon: <Shop size="12px" />,
    component: <Theme />,
    noCollapse: true,
  },
 
  {
    type: "collapse",
    name: "Enrollments",
    key: "enrollmentsData",
    route: "/enrollmentsData",
    icon: <Shop size="12px" />,
    component: <EnrollmentsData />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Events",
    key: "Events",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "OrgHierarchy",
    key: "OrgHierarchy",
    route: "/orghierarchy",
    icon: <Shop size="12px" />,
    component: <OrgHierarchy />,
    noCollapse: true,
  },
 
 
  {
    type: "collapse",
    name: "LetterHeads",
    key: "letter-heads",
    route: "/letter-heads",
    icon: <Shop size="12px" />,
    component: <LetterHead/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Employee IdCard",
    key: "Employee IdCard",
    route: "/employeeIdCard",
    icon: <Shop size="12px" />,
    component: <EmployeeIdCard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Policy",
    key: "Policy",
    route: "/orgPolicies",
    icon: <Shop size="12px" />,
    component: <OrgPolicies />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Stationary",
    key: "stationaryItems",
    route: "/stationaryItems",
    icon: <Shop size="12px" />,
    component: <StationaryItems />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Stores",
    key: "assets-list",
    route: "/assets-list",
    icon: <Shop size="12px" />,
    component: <AssetList />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Procurement",
    key: "procurement-list",
    route: "/procurement-list",
    icon: <Shop size="12px" />,
    component: <ProcurementList />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Festival",
    key: "festival-list",
    route: "/festival-list",
    icon: <Shop size="12px" />,
    component: <FestivalList/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Query",
    key: "ticket-list",
    route: "/ticket-list",
    icon: <CreditCard size="12px" />,
    component: <TicketTable />,
    noCollapse: true,
  },
 
  {
    type: "collapse",
    name: "MarksData",
    key: "marksData",
    route: "/marksData",
    icon: <CreditCard size="12px" />,
    component: <MarksData />,
    noCollapse: true,
  },

];

export default routes;
