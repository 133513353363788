// import React, { useEffect, useState } from "react";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftButton from "components/SoftButton";
// import { Card, Grid, MenuItem, Select, FormControl, InputLabel, FormHelperText, Tooltip } from "@mui/material";
// import SoftInput from "components/SoftInput";
// import { APIData, org } from '../authentication/APIData';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import { Link, useNavigate } from 'react-router-dom';
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import * as AiIcons from "react-icons/ai";

// function AddAssetgroup() {
//     const [assetGroupName, setAssetGroupName] = useState("");
//     const [assetType, setAssetType] = useState("");
//     const [image, setImage] = useState(null);
//     const [imagePreview, setImagePreview] = useState("");
//     const [errors, setErrors] = useState({ assetType: "" });
//     const [orgLogo, setOrgLogo] = useState("");
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchOrgData = async () => {
//             try {
//                 const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
//                 if (!response.ok) {
//                     throw new Error("Failed to fetch organization data");
//                 }
//                 const data = await response.json();
//                 setOrgLogo(data.orgLogo);
//             } catch (error) {
//                 console.error("Error fetching organization data:", error);
//             }
//         };

//         fetchOrgData();
//     }, []);

//     const handleAssetGroupNameChange = (event) => {
//         setAssetGroupName(event.target.value);
//     };

//     const handleAssetTypeChange = (event) => {
//         setAssetType(event.target.value);
//         setErrors({ ...errors, assetType: "" });
//     };

//     const handleImageChange = (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             if (file.size > 1000000) {
//                 Swal.fire({
//                     icon: "error",
//                     title: "Oops...",
//                     text: "File too large. Max file size is 1MB.",
//                 });
//                 setImage(null);
//                 setImagePreview("");
//                 return;
//             }
//             setImage(file);
//             setImagePreview(URL.createObjectURL(file));
//         }
//     };


//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         const newErrors = {};

//         if (!assetGroupName) {
//             newErrors.assetGroupName = "Asset Group Name is required.";
//         }

//         if (!assetType) {
//             newErrors.assetType = "Asset type is required.";
//         }

//         setErrors(newErrors);

//         if (Object.keys(newErrors).length === 0) {
//             try {
//                 setLoading(true);

//                 const url = `${APIData.api}assetgroup/`;
//                 const AssetGroupData = {
//                     asset_group_imageUrl: "",
//                     asset_group_name: assetGroupName,
//                     type: assetType,
//                     org: org
//                 };

//                 const response = await axios.post(url, AssetGroupData, { headers: APIData.headers });
//                 const assetGroupId = response.data.asset_group_id;

//                 if (image) {
//                     if (image.size > 1000000) {
//                         Swal.fire({
//                             icon: "error",
//                             title: "Oops...",
//                             text: "File too large",
//                             footer: "Max file size is 1MB"
//                         });
//                         setLoading(false);
//                         return;
//                     }

//                     const imageForm = new FormData();
//                     imageForm.append('id', assetGroupId);
//                     imageForm.append('file', image);

//                     const imageUploadUrl = `${APIData.api}assetgroup/${assetGroupId}/image`;

//                     await axios.post(imageUploadUrl, imageForm, { headers: APIData.headers });
//                 }

//                 Swal.fire({
//                     icon: "success",
//                     title: "Asset Group Added Successfully",
//                     showConfirmButton: false,
//                     timer: 1500
//                 });

//                 setLoading(false);

//                 setTimeout(() => {
//                     navigate("/assets-list");
//                 }, 1500);

//             } catch (error) {
//                 setLoading(false);
//                 Swal.fire({
//                     icon: "error",
//                     title: "Oops...",
//                     text: "Failed to create Asset Group",
//                     footer: error.message
//                 });
//             }
//         }
//     };


//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4">
//                 <Grid item xs={10} sm={10} md={8} lg={6}>
//                     <Card className="w-full">
//                         <SoftBox p={3} mb={1} display="flex" alignItems="center">
//                         <Tooltip title="Close" arrow>
//                             <Link to="/assets-list">
//                                 <AiIcons.AiOutlineCloseCircle />
//                             </Link>
//                         </Tooltip>
//                             <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
//                             Add an Asset Group
//                             </SoftTypography>
//                         </SoftBox>
//                         {/* <SoftBox mb={2} display="flex" alignItems="center" justifyContent="center">
//                             {orgLogo ? (
//                                 <img
//                                     src={orgLogo}
//                                     alt="Organization Logo"
//                                     style={{ height: "100px", width: "100px", objectFit: "contain" }}
//                                     onError={() => setOrgLogo("")}
//                                 />
//                             ) : (
//                                 <SoftTypography variant="body2">No logo available</SoftTypography>
//                             )}
//                         </SoftBox> */}
//                         <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
//                             <SoftBox mb={2}>
//                                 <SoftTypography variant="h6">Asset Group Name *</SoftTypography>
//                                 <FormControl fullWidth error={!!errors.assetGroupName}>
//                                     <SoftInput
//                                         placeholder="Asset Group Name"
//                                         value={assetGroupName}
//                                         onChange={handleAssetGroupNameChange}
//                                         name="assetGroupName"
//                                     />
//                                     {errors.assetGroupName && (
//                                         <FormHelperText>{errors.assetGroupName}</FormHelperText>
//                                     )}
//                                 </FormControl>
//                             </SoftBox>
//                             <SoftBox mb={2}>
//                                 <SoftTypography variant="h6">Asset Type *</SoftTypography>
//                                 <FormControl fullWidth error={!!errors.assetType}>
//                                     <InputLabel id="asset-type-label"></InputLabel>
//                                     <Select
//                                         labelId="asset-type-label"
//                                         value={assetType}
//                                         onChange={handleAssetTypeChange}
//                                         name="assetType"
//                                         displayEmpty
//                                     >
//                                         <MenuItem value="">Select Asset Type</MenuItem>
//                                         <MenuItem value="Hardware">Hardware</MenuItem>
//                                         <MenuItem value="Software">Software</MenuItem>
//                                         <MenuItem value="Stationary">Stationary</MenuItem>
//                                     </Select>
//                                     {errors.assetType && (
//                                         <FormHelperText>{errors.assetType}</FormHelperText>
//                                     )}
//                                 </FormControl>
//                             </SoftBox>
//                             <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
//                                 <input
//                                     accept="image/*"
//                                     style={{ display: 'none' }}
//                                     id="upload-image"
//                                     type="file"
//                                     onChange={handleImageChange}
//                                 />
//                                 <label htmlFor="upload-image">
//                                     <SoftButton variant="contained" component="span">
//                                         Upload Image
//                                     </SoftButton>
//                                 </label>
//                                 {imagePreview && (
//                                     <SoftBox mt={2} display="flex" justifyContent="center" alignItems="center">
//                                         <img src={imagePreview} alt="Preview" style={{ width: '200px', height: 'auto', objectFit: 'contain' }} />
//                                     </SoftBox>
//                                 )}
//                             </SoftBox>
//                             <SoftBox mt={4} mb={1}>
//                                 <SoftButton type="submit" variant="gradient" color="info" fullWidth disabled={loading}>
//                                     {loading ? "Submitting..." : "Submit"}
//                                 </SoftButton>
//                             </SoftBox>
//                         </SoftBox>
//                     </Card>
//                 </Grid>
//             </Grid>
//         </DashboardLayout>
//     );
// }

// export default AddAssetgroup;


import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { Card, Grid, MenuItem, Select, FormControl, InputLabel, FormHelperText, Tooltip } from "@mui/material";
import SoftInput from "components/SoftInput";
import { APIData, org } from 'authentication/APIData';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as AiIcons from "react-icons/ai";

function AddAssetgroup() {
    const [assetGroupName, setAssetGroupName] = useState("");
    const [assetType, setAssetType] = useState("");
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState("");
    const [errors, setErrors] = useState({ assetType: "", image: "" });
    const [orgLogo, setOrgLogo] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrgData = async () => {
            try {
                const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch organization data");
                }
                const data = await response.json();
                setOrgLogo(data.orgLogo);
            } catch (error) {
                console.error("Error fetching organization data:", error);
            }
        };

        fetchOrgData();
    }, []);

    const handleAssetGroupNameChange = (event) => {
        setAssetGroupName(event.target.value);
    };

    const handleAssetTypeChange = (event) => {
        setAssetType(event.target.value);
        setErrors({ ...errors, assetType: "" });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "File too large. Max file size is 1MB.",
                });
                setImage(null);
                setImagePreview("");
                return;
            }
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
            setErrors({ ...errors, image: "" });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = {};

        if (!assetGroupName) {
            newErrors.assetGroupName = "Asset Group Name is required.";
        }

        if (!assetType) {
            newErrors.assetType = "Asset type is required.";
        }

        if (!image) {
            newErrors.image = "Image is required.";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                setLoading(true);

                const url = `${APIData.api}assetgroup/`;
                const AssetGroupData = {
                    asset_group_imageUrl: "",
                    asset_group_name: assetGroupName,
                    type: assetType,
                    org: org
                };

                const response = await axios.post(url, AssetGroupData, { headers: APIData.headers });
                const assetGroupId = response.data.asset_group_id;

                if (image) {
                    const imageForm = new FormData();
                    imageForm.append('id', assetGroupId);
                    imageForm.append('file', image);

                    const imageUploadUrl = `${APIData.api}assetgroup/${assetGroupId}/image`;

                    await axios.post(imageUploadUrl, imageForm, { headers: APIData.headers });
                }

                Swal.fire({
                    icon: "success",
                    title: "Asset Group Added Successfully",
                    showConfirmButton: false,
                    timer: 1500
                });

                setLoading(false);

                setTimeout(() => {
                    navigate("/assets-list");
                }, 1500);

            } catch (error) {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Failed to create Asset Group",
                    footer: error.message
                });
            }
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
                <Grid item xs={10} sm={10} md={8} lg={6}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                        <Tooltip title="Close" arrow>
                            <Link to="/assets-list">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                        </Tooltip>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                            Add an Asset Group
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h6">Asset Group Name *</SoftTypography>
                                <FormControl fullWidth error={!!errors.assetGroupName}>
                                    <SoftInput
                                        placeholder="Asset Group Name"
                                        value={assetGroupName}
                                        onChange={handleAssetGroupNameChange}
                                        name="assetGroupName"
                                    />
                                    {errors.assetGroupName && (
                                        <FormHelperText>{errors.assetGroupName}</FormHelperText>
                                    )}
                                </FormControl>
                            </SoftBox>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h6">Asset Type *</SoftTypography>
                                <FormControl fullWidth error={!!errors.assetType}>
                                    <InputLabel id="asset-type-label"></InputLabel>
                                    <Select
                                        labelId="asset-type-label"
                                        value={assetType}
                                        onChange={handleAssetTypeChange}
                                        name="assetType"
                                        displayEmpty
                                    >
                                        <MenuItem value="">Select Asset Type</MenuItem>
                                        <MenuItem value="Hardware">Hardware</MenuItem>
                                        <MenuItem value="Software">Software</MenuItem>
                                        <MenuItem value="Stationary">Stationary</MenuItem>
                                    </Select>
                                    {errors.assetType && (
                                        <FormHelperText>{errors.assetType}</FormHelperText>
                                    )}
                                </FormControl>
                            </SoftBox>
                            <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="upload-image"
                                    type="file"
                                    onChange={handleImageChange}
                                />
                                <label htmlFor="upload-image">
                                    <SoftButton variant="contained" component="span">
                                        Upload Image
                                    </SoftButton>
                                </label>
                                {imagePreview && (
                                    <SoftBox mt={2} display="flex" justifyContent="center" alignItems="center">
                                        <img src={imagePreview} alt="Preview" style={{ width: '200px', height: 'auto', objectFit: 'contain' }} />
                                    </SoftBox>
                                )}
                                {errors.image && (
                                    <FormHelperText error>{errors.image}</FormHelperText>
                                )}
                            </SoftBox>
                            <SoftBox mt={4} mb={1}>
                                <SoftButton type="submit" variant="gradient" color="info" fullWidth disabled={loading}>
                                    {loading ? "Submitting..." : "Submit"}
                                </SoftButton>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default AddAssetgroup;
