import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from 'components/SoftButton';
import SoftInput from "components/SoftInput";
import { Grid, Card } from '@mui/material';
import { APIData } from 'authentication/APIData';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import * as AiIcons from "react-icons/ai";
import Swal from "sweetalert2";

const EditTicket = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ticket, setTicket] = useState(null);
  const [status, setStatus] = useState('');

  useEffect(() => {
    const fetchTicketDetails = async () => {
      try {
        const response = await fetch(`${APIData.api}Ticket/${id}`, {
          headers: APIData.headers,
        });
        const data = await response.json();
        setTicket(data);
        setStatus(data.status);
      } catch (error) {
        console.error('Error fetching ticket details:', error);
      }
    };

    fetchTicketDetails();
  }, [id]);

  const handleStatusChange = async (e) => {
    e.preventDefault();
    try {
      const updatedTicket = {
        id: ticket.id,
        ticket_id: ticket.ticket_id,
        consumerId: ticket.consumerId || "string",
        product: ticket.product,
        custom_product: ticket.custom_product || "string",
        date: ticket.date,
        name: ticket.name,
        phone_number: ticket.phone_number,
        description: ticket.description,
        type: ticket.type,
        status,
        priority: ticket.priority,
        emailid: ticket.emailid,
        image_url: ticket.image_url || "",
        org: ticket.org || "c4e",
        sla: ticket.sla || "4-24 hours",
      };
  
      const response = await fetch(`${APIData.api}Ticket/id?id=${id}`, {
        method: 'PUT',
        headers: {
          ...APIData.headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedTicket),
      });
  
      if (response.ok) {
        Swal.fire({
            icon: "success",
            title: "Update",
            text: `${ticket.ticket_id} Updated Sucessfully.`,
            showConfirmButton: false,
            timer: 1500
        });
        navigate("/ticket-list");
      } else {
        console.error('Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };  

  if (!ticket) return <SoftTypography>Loading ticket details...</SoftTypography>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
        <Grid item xs={8}>
          <Card sx={{ height: 'auto' }}>
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Link to="/ticket-list">
                <AiIcons.AiOutlineCloseCircle />
              </Link>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                Edit Ticket #{ticket.ticket_id}
              </SoftTypography>
            </SoftBox>
            <SoftBox pt={1} pb={3} px={3}>
              <form onSubmit={handleStatusChange}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Name</SoftTypography>
                  <SoftInput value={ticket.name} disabled />
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Email</SoftTypography>
                  <SoftInput value={ticket.emailid} disabled />
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Product</SoftTypography>
                  <SoftInput value={ticket.product} disabled />
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Phone</SoftTypography>
                  <SoftInput value={ticket.phone_number} disabled />
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Priority</SoftTypography>
                  <SoftInput value={ticket.priority} disabled />
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Description</SoftTypography>
                  <SoftInput 
                  multiline
                  rows={4}
                  value={ticket.description} />
                </SoftBox>

                <SoftBox mb={3}>
                  <SoftTypography variant="h6">Status</SoftTypography>
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    style={{ width: '100%', padding: '8px', borderRadius: '4px' }}
                  >
                    <option value="REQUESTED">Requested</option>
                    <option value="PENDING">Pending</option>
                    <option value="RESOLVED">Completed</option>
                  </select>
                </SoftBox>

                <SoftBox mt={2}>
                  <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                    Update Status
                  </SoftButton>
                </SoftBox>
              </form>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default EditTicket;
